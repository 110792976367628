import React, { useCallback, useEffect, useRef, useState } from 'react';
import {  Col, Row } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import {  useNavigate, useParams } from 'react-router-dom';
import { ChatIcon, LoaderIcon } from '../Components/Global/Icons';
import {  GetChatByTestId, GetUserPreferenceDetails, getprojectbyidforuser } from '../Services/PtaasApiService';
import { FadeAlert, LinkButton } from '../Components/Global/Transitions';
import Tests from '../Models/Tests';
import SetupForm from '../Components/Setup/SetupForm';
import SetupProgress from '../Components/Setup/SetupProgress';
import SetupCommercials from '../Components/Setup/SetupQuote';
import ProjectTestTypes from '../Components/Overview/ProjectTestTypes';
import ProjectCommercials from '../Components/Overview/ProjectCommercials';



const ProjectSetupPage: React.FC = () => {
  
  const { id, stageid } = useParams();
  const navigate=useNavigate();
  const [status, setStatus] = useState('');
  const { instance, accounts } = useMsal();
  const [test, setTest] = useState<Tests>();
  const [project, setProject] = useState('');
  const [loading, setLoading] = useState(true);
  const [testTypes, setTestTypes] = useState([]);
  const handleChange = (stage) => {setStage(stage); navigate(`/projects/${id}/setup/${stage}`);}
  const [fetchError, setFetchError] = useState(false);
  const [chatid,setChatid]=useState('');
  const isSetup = window.location.href.includes('setup');
  const [stage, setStage] = useState(stageid ? parseInt(stageid, 10) : 1);
  const [isScoping, isCommercials, isPrereqs] = [1, 2, 3].map(s => stage === s);
  const[userinfo,setUserInfo] =useState<User>();
  const name = project + ': ' + { 1: 'Scoping', 2: 'Commercials', 3: 'Prerequisites' }[stage];

  const isInitialMount = useRef(true);

  const fetchTest= useCallback(async (): Promise<void> => {
    setLoading(true);
    let returnedTest: Tests;
    try {
      returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
      const returnedUserinfo=await GetUserPreferenceDetails(instance,accounts[0]);
      setUserInfo(returnedUserinfo);
      setTest(returnedTest);
      setStatus(returnedTest?.status);
      setTestTypes(returnedTest?.testType);
      setProject(returnedTest?.projectName);
      const returnedid= await GetChatByTestId(instance, accounts[0],returnedTest.id);
    setChatid(returnedid);
    } catch {
      setFetchError(true)
    } finally {
      setLoading(false);
    }
  },[accounts, instance,id]);
  
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchTest();
    }
  }, [fetchTest]);


  return (loading ? <LoaderIcon /> : (
    <>
    <Row>
      <Col sm={8} as='h1'>{project && name}</Col>
      <Col sm={4} className='text-end'>
        <LinkButton to={`/messaging?id=${chatid}`}><ChatIcon />Messaging</LinkButton>
      </Col>
      <Col xl={8}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
        {isScoping ? <SetupForm group='scoping' testTypes={testTypes} onStageChange={handleChange} /> : null}
         {isPrereqs ? <SetupForm group='prereq' testTypes={testTypes} onStageChange={handleChange} /> : null} 
        {isCommercials ? <SetupCommercials status={status} test={test} onStageChange={handleChange} userinfo={userinfo}/> : null}
      </Col>
      <Col xl={4}>
        <ProjectTestTypes test={test} testTypes={testTypes} isDisabled={isPrereqs} OnProjectTestTypeChange={fetchTest} />
        {isPrereqs ? <ProjectCommercials status={status} /> : null}
      </Col>
      {isSetup ? <SetupProgress stage={stage} onStageChange={handleChange} /> : null}
    </Row>
    </>
  ));
};

export default ProjectSetupPage;