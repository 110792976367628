import React  from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import './config.scss';
import './App.css';
import PageLayout from './PageLayout';
import DashboardPage from './Pages/DashboardPage';
import ProjectSetupPage from './Pages/ProjectSetupPage';
import ProjectQuotePage from './Pages/ProjectQuotePage';
import ProjectOverviewPage from './Pages/ProjectOverviewPage';
import ReportPhasePage from './Pages/ReportPhasePage';
import ReportSummaryPage from './Pages/ReportSummaryPage';
import ReportFindingPage from './Pages/ReportFindingPage';
import FAQsPage from './Pages/FAQsPage';
import MessagingPage from './Pages/MessagingPage';
import PreferencesPage from './Pages/PreferencesPage';


const App: React.FC = () => {

	return (
		<><Routes>
				<Route path='/' element={<PageLayout />}>
					<Route index element={<Navigate to='/dashboard' />} />
					<Route path='dashboard' element={<DashboardPage />} />
					<Route path='projects' element={<DashboardPage />} />
					<Route path='projects/:id' element={<ProjectOverviewPage />} />
					<Route path='projects/:id/quote' element={<ProjectQuotePage />} />
					<Route path='projects/:id/setup' element={<ProjectSetupPage />} />
					<Route path='projects/:id/setup/:stageid' element={<ProjectSetupPage />} />
					<Route path='projects/:id/prereqs/:stageid' element={<ProjectSetupPage />} />
					<Route path='projects/:id/:canopyid' element={<ProjectOverviewPage />} />
					<Route path='projects/:id/:canopyid/reports' element={<ReportSummaryPage />} />
					<Route path='projects/:id/:canopyid/reports/:phaseid' element={<ReportPhasePage />} />
					<Route path="projects/:id/:canopyid/reports/:phaseid/finding/:findid" element={<ReportFindingPage />} />
					<Route path='faqs' element={<FAQsPage />} />
					<Route path='messaging' element={<MessagingPage />} />
					<Route path='preferences' element={<PreferencesPage />} />
					<Route path='preferences/:tab' element={<PreferencesPage />} />
				</Route>
			</Routes></>
		
		
	);
};

export default App;