import React from 'react';
import { Accordion } from 'react-bootstrap';
import { CountUpNumber, FadeAccordion } from '../Global/Transitions';



interface ReportDistributionProps {
  low: number;
  info: number;
  high: number;
  total: number;
  medium: number;
  critical: number;  
}

const ReportDistribution: React.FC<ReportDistributionProps> = ({ total, critical, high, medium, low, info }) => {
  const data = [
    { label: 'total', value: total },
    { label: 'critical', value: critical },
    { label: 'high', value: high },
    { label: 'medium', value: medium },
    { label: 'low', value: low },
    { label: 'info', value: info },
  ];
  const sortedData = data.sort((a, b) => b.value - a.value);
  //const firstValue = sortedData[1].value;
  const secondValue = sortedData[2].value;
  const thirdValue = sortedData[3].value;
  const fourthValue = sortedData[4].value;
  const lastValue = sortedData[5].value;
  const firstLabel = sortedData[1].label;
  const secondLabel = sortedData[2].label;
  const thirdLabel = sortedData[3].label;
  const fourthLabel = sortedData[4].label;
  const lastLabel = sortedData[5].label;
  const secondUse = ((secondValue + thirdValue + fourthValue + lastValue) / total * 100) - 2; 
  const thirdUse = ((thirdValue + fourthValue + lastValue) / total * 100) - 2;
  const fourthUse = ((fourthValue + lastValue) / total * 100) - 2;
  const lastUse = ((lastValue) / total * 100) - 2;

  return (
    <FadeAccordion defaultActiveKey='default'>
      <Accordion.Item eventKey='default'>
        <Accordion.Button as='h3' className='bg-white'>Rating Distribution</Accordion.Button>
        <Accordion.Body>
          <svg xmlns='http://www.w3.org/2000/svg' className='w-100 h-auto donuts' height='200' viewBox='0 0 75 36'>
            <defs><style>{'.donuts circle{strokeWidth:3.5;strokeLinecap:round;fill:none;stroke:currentColor}.donuts text{font-family:Satoshi,sans-serif;font-weight:500;opacity:.6}.donuts .text{text-anchor:middle;dominant-baseline:middle}'}</style></defs>
            <g transform='translate(1.75 1.75)' >
              <circle cx='16' cy='16' r='16' className='text-light none' strokeDasharray='100 0' />
              {firstLabel === 'critical' && <circle cx="16" cy="16" r="16" className="text-danger" strokeDasharray="96.3 3.7" />}
              {firstLabel === 'high' && <circle cx="16" cy="16" r="16" className="text-warning" strokeDasharray="96.3 3.7" />}
              {firstLabel === 'medium' && <circle cx="16" cy="16" r="16" className="text-caution" strokeDasharray="96.3 3.7" />}
              {firstLabel === 'low' && <circle cx="16" cy="16" r="16" className="text-success" strokeDasharray="96.3 3.7" />}
              {firstLabel === 'info' && <circle cx="16" cy="16" r="16" className="text-gray" strokeDasharray="96.3 3.7" />}
              {secondLabel === 'critical' && secondValue > 0 && <circle cx="16" cy="16" r="16" className="text-danger" strokeDasharray={secondUse + ' ' + (100 - secondUse)} />}
              {secondLabel === 'high' && secondValue > 0 && <circle cx="16" cy="16" r="16" className="text-warning" strokeDasharray={secondUse + ' ' + (100 - secondUse)} />}
              {secondLabel === 'medium' && secondValue > 0 && <circle cx="16" cy="16" r="16" className="text-caution" strokeDasharray={secondUse + ' ' + (100 - secondUse)} />}
              {secondLabel === 'low' && secondValue > 0 && <circle cx="16" cy="16" r="16" className="text-success" strokeDasharray={secondUse + ' ' + (100 - secondUse)} />}
              {secondLabel === 'info' && secondValue > 0 && <circle cx="16" cy="16" r="16" className="text-gray" strokeDasharray={secondUse + ' ' + (100 - secondUse)} />}
              {thirdLabel === 'critical' && thirdValue > 0 && <circle cx="16" cy="16" r="16" className="text-danger" strokeDasharray={thirdUse + ' ' + (100 - thirdUse)} />}
              {thirdLabel === 'high' && thirdValue > 0 && <circle cx="16" cy="16" r="16" className="text-warning" strokeDasharray={thirdUse + ' ' + (100 - thirdUse)} />}
              {thirdLabel === 'medium' && thirdValue > 0 && <circle cx="16" cy="16" r="16" className="text-caution" strokeDasharray={thirdUse + ' ' + (100 - thirdUse)} />}
              {thirdLabel === 'low' && thirdValue > 0 && <circle cx="16" cy="16" r="16" className="text-success" strokeDasharray={thirdUse + ' ' + (100 - thirdUse)} />}
              {thirdLabel === 'info' && thirdValue > 0 && <circle cx="16" cy="16" r="16" className="text-gray" strokeDasharray={thirdUse + ' ' + (100 - thirdUse)} />}
              {fourthLabel === 'critical' && fourthValue > 0 && <circle cx="16" cy="16" r="16" className="text-danger" strokeDasharray={fourthUse + ' ' + (100 - fourthUse)} />}
              {fourthLabel === 'high' && fourthValue > 0 && <circle cx="16" cy="16" r="16" className="text-warning" strokeDasharray={fourthUse + ' ' + (100 - fourthUse)} />}
              {fourthLabel === 'medium' && fourthValue > 0 && <circle cx="16" cy="16" r="16" className="text-caution" strokeDasharray={fourthUse + ' ' + (100 - fourthUse)} />}
              {fourthLabel === 'low' && fourthValue > 0 && <circle cx="16" cy="16" r="16" className="text-success" strokeDasharray={fourthUse + ' ' + (100 - fourthUse)} />}
              {fourthLabel === 'info' && fourthValue > 0 && <circle cx="16" cy="16" r="16" className="text-gray" strokeDasharray={fourthUse + ' ' + (100 - fourthUse)} />}
              {lastLabel === 'critical' && lastValue > 0 && <circle cx="16" cy="16" r="16" className="text-danger" strokeDasharray={lastUse + ' ' + (100 - lastUse)} />}
              {lastLabel === 'high' && lastValue > 0 && <circle cx="16" cy="16" r="16" className="text-warning" strokeDasharray={lastUse + ' ' + (100 - lastUse)} />}
              {lastLabel === 'medium' && lastValue > 0 && <circle cx="16" cy="16" r="16" className="text-caution" strokeDasharray={lastUse + ' ' + (100 - lastUse)} />}
              {lastLabel === 'low' && lastValue > 0 && <circle cx="16" cy="16" r="16" className="text-success" strokeDasharray={lastUse + ' ' + (100 - lastUse)} />}
              {lastLabel === 'info' && lastValue > 0 && <circle cx="16" cy="16" r="16" className="text-gray" strokeDasharray={lastUse + ' ' + (100 - lastUse)} />}
            </g>
            <g transform='translate(18 15.5)' >
              <text className='dark-white text' fontSize={5} x='0' y='0'><CountUpNumber number={total} /></text>
              <text className='dark-white text' fontSize={3} x='0' y='0' transform='translate(0 5)'>Total Issues</text>
            </g>
            <g transform='translate(43 2.7)'>
              <g>
                <rect width='4' height='2.2' rx='1.3' fill='currentColor' className='text-danger' transform='translate(0 0)' />
                <rect width='4' height='2.2' rx='1.3' fill='currentColor' className='text-warning' transform='translate(0 7)' />
                <rect width='4' height='2.2' rx='1.3' fill='currentColor' className='text-caution' transform='translate(0 14)' />
                <rect width='4' height='2.2' rx='1.3' fill='currentColor' className='text-success' transform='translate(0 21)' />
                <rect width='4' height='2.2' rx='1.3' fill='currentColor' className='text-gray' transform='translate(0 28)' />
              </g>
              <g>
                <text className='dark-white' fontSize={2.8} transform='translate(7 02)'>{critical} Critical</text>
                <text className='dark-white'fontSize={2.8} transform='translate(7 09)'>{high} High</text>
                <text className='dark-white'fontSize={2.8} transform='translate(7 16)'>{medium} Medium</text>
                <text className='dark-white'fontSize={2.8} transform='translate(7 23)'>{low} Low</text>
                <text className='dark-white'fontSize={2.8} transform='translate(7 30)'>{info} Info</text>
              </g>
            </g>
          </svg>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ReportDistribution;