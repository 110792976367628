import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ArrowIcon, CountNumberIcon, CountTickIcon } from '../../Components/Global/Icons';



interface SetupProgressProps {
  stage: number;
  onStageChange: (newStage: number) => void;
}

const SetupProgress: React.FC<SetupProgressProps> = ({ stage, onStageChange }) => {
  const [isScoping, isCommercials, isPreReqs] = [1, 2, 3].map(s => stage === s);
  const modalStyles: React.CSSProperties = {
    width: '100%',
    bottom: '5vh',
    margin: 'auto',
    zIndex: 999999,
    position: 'fixed',
    textAlign: 'center',
    marginLeft: 'calc(-1 * (var(--sidebar) + 1rem))',
  };

  return (
    <div style={modalStyles}>
      <ButtonGroup size='lg' className='rounded-pill overflow-hidden shadow-lg'>
        <Button variant='gray-dark' className='pe-none'>
          {isScoping ? <CountNumberIcon number={1} isSwitch={isScoping} isGradient={isScoping} /> : <CountTickIcon />}
          Scoping
        </Button>
        <ArrowIcon />
        <Button variant='gray-dark' className='pe-none'>
          {isPreReqs ? <CountTickIcon /> : <CountNumberIcon number={2} isSwitch={isCommercials} isGradient={isCommercials} />}
          Commercials
        </Button>
        <ArrowIcon />
        <Button variant='gray-dark' className='pe-none'>
          <CountNumberIcon number={3} isSwitch={isPreReqs} isGradient={isPreReqs} />
          Prerequisites
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default SetupProgress;