import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FadeAccordion, LinkButton } from '../Global/Transitions';
import { Accordion, ProgressBar } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { getFilesByProjectTicketnumber, getPrereqQuestionByQuestionId, getprojectbyidforuser } from '../../Services/PtaasApiService';
import Assignment from '../../Models/Assignment';
import Tests from '../../Models/Tests';

interface ProjectPreReqsProps {
  assignments: Assignment[];
}

const ProjectPreReqs: React.FC<ProjectPreReqsProps> = ({ assignments }) => {
  const { id } = useParams();
  const { instance, accounts } = useMsal();
  const [test, setTest] = useState<Tests | null>(null);
  const [totalLength, setTotalLength] = useState(0);
  const [completed, setCompleted] = useState(0);

  const getVariant = (percent) => {
    if (percent <= 15) return 'danger';
    if (percent <= 65) return 'warning';
    return 'success';
  };

  const fetchPrerequisites = useCallback(async (): Promise<void> => {
    let total = 0;

    for (const assignment of assignments) {
      try {
        const prerequisites = await getPrereqQuestionByQuestionId(instance, accounts[0], assignment.assignmentId);
        total += prerequisites.length;
      } catch (error) {
        console.error(`Failed to fetch prerequisites for assignment ID ${assignment.assignmentId}:`, error);
      }
    }
    setTotalLength(total);
  }, [assignments, instance, accounts]);

  const fetchProjects = useCallback(async (): Promise<void> => {
    try {
      const returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
      setTest(returnedTest);
    } catch (error) {
      console.error('Failed to fetch project:', error);
    }
  }, [instance, accounts, id]);

  const calculateCompletedCount = useCallback(async (): Promise<void> => {
    if (!test) return;

    let completedCount = 0;

    for (const assignment of assignments) {
      if (assignment.prereq) {
        for (const question of assignment.prereq) {
          if (question.answerText?.trim() !== "") {
            completedCount += 1;
          } else {
            try {
              const testFiles = await getFilesByProjectTicketnumber(instance, accounts[0], test.ticketno);
              const files = testFiles.filter(c => c.questionId === question.spId);
              if (files.length > 0) {
                completedCount += 1;
              }
            } catch (error) {
              console.error('Error fetching test files:', error);
            }
          }
        }
      }
    }

    setCompleted(completedCount);
  }, [assignments, instance, accounts, test]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    fetchPrerequisites();
  }, [fetchPrerequisites]);

  useEffect(() => {
    if (test?.ticketno) {
      calculateCompletedCount();
    }
  }, [test, calculateCompletedCount]);

  const totalCount = totalLength;
  

 
  const completionRate = totalCount > 0 ? Math.min(Math.round((completed / totalCount) * 100), 100) : 0;


  const getBG = (percent) => {
    if (percent <= 25) return 'bg-danger-subtle';
    if (percent <= 75) return 'bg-warning-subtle';
    return 'bg-success-subtle';
  };

  return (
    <FadeAccordion defaultActiveKey='0'>
      <Accordion.Item eventKey={completionRate >= 70 ? '1' : '0'}>
        <Accordion.Button as='h3'>
          Prerequisites
          <ProgressBar
            striped
            animated
            now={completionRate}
            variant={getVariant(completionRate)}
            style={{ height: 25, width: 100 }}
            className={'ms-3 rounded-5 ' + getBG(completionRate)}
            label={(completionRate > 16 ? completionRate : '') + (completionRate > 32 ? '%' : '')}
          />
        </Accordion.Button>
        <Accordion.Body>
          <p>You have completed {completionRate} percent of Pre-requisites.</p>
          <LinkButton variant={getVariant(completionRate)} to={'/projects/' + id + '/prereqs/3'} className='btn-width text-white'>
            {completionRate > 99 ? 'Edit' : 'Continue'}
          </LinkButton>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ProjectPreReqs;