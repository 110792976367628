import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { PelletIcon } from '../Global/Icons';
import { Accordion, Col, Row } from 'react-bootstrap';
import { FadeAccordion } from '../Global/Transitions';
import { Chart, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import Phase from '../../Models/Phase';
import ExportButton from './ReportExport';
import RatingProps from '../../Utilities/RatingProps';



interface ReportChartProps {
  phase?: Phase;
}

const ReportChart: React.FC<ReportChartProps> = ({ phase }) => {
  const ratings = [1, 2, 3, 4, 5];
  const counts: Record<string, Record<number, number>> = {};
  Chart.register(Legend, Tooltip, BarElement, LinearScale, CategoryScale);
  const categories = ['Vulnerability', 'Weakness', 'Information Leakage', 'Caution'];

  categories.forEach(type => {
    counts[type] = {};
    ratings.forEach(rating => counts[type][rating] = 0)
  });

  phase.findings.forEach((finding) => {
    const { attack_class__type, rating } = finding;
    if (categories.includes(attack_class__type) && ratings.includes(rating)) {
      counts[attack_class__type][rating]++
    }
  });

  const filteredCategories = categories.filter(category => (
    ratings.some(rating => counts[category][rating] > 0)
  ));

  const filteredRatings = ratings.filter(rating => (
    filteredCategories.some(category => counts[category][rating] > 0))
  );

  const options: ChartOptions<'bar'> = {
    maxBarThickness: 70,
    plugins: { legend: { display: false } },
    scales: {
      x: { grid: { display: false }, ticks: { font: { size: 13, family: 'Satoshi, sans-serif' } } },
      y: { grid: { color: '#f5f5f5' }, ticks: { stepSize: 1, font: { size: 13, family: 'Satoshi, sans-serif' } } }
    }
  } as any;

  const data = {
    labels: filteredCategories,
    datasets: filteredRatings.map(rating => ({
      borderRadius: 10,
      label: RatingProps[rating].name,
      backgroundColor: RatingProps[rating].bg,
      hoverBackgroundColor: RatingProps[rating].hover,
      data: filteredCategories.map(type => counts[type][rating])
    })),
  };

  return (
    <FadeAccordion defaultActiveKey='default'>
      <Accordion.Item eventKey="default" className='position-relative overflow-hidden gradient'>
        <Row className='border-bottom m-0 p-2'>
          <Col><h3 className='pt-1'>Findings by Category and Rating</h3></Col>
          <Col className='text-end pe-2'><ExportButton data={phase} /></Col>
        </Row>
        <Accordion.Body>
          {ratings.map(rating => (
            <p key={rating} className='d-inline-block me-5'>
              <PelletIcon className={RatingProps[rating].color} />
              {RatingProps[rating].name}
            </p>
          ))}
          <Bar data={data} options={options} />
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ReportChart;
