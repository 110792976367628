import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import React, { useEffect, useState, useCallback } from 'react';
import { getAccessToken, logout } from './AccountService';
import config from "../config.json";
import { useMsal } from '@azure/msal-react';

interface AppInitializerProps {
  children: React.ReactNode;
}

const AppInitializer: React.FC<AppInitializerProps> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { instance, accounts } = useMsal();

  const getFetchOptions = useCallback(async (
    instance: IPublicClientApplication,
    account: AccountInfo
  ): Promise<RequestInit> => {
    const accessToken = await getAccessToken(instance, account);

    const headers = new Headers({
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    });

    return {
      method: "GET",
      headers,
    };
  }, []);

  useEffect(() => {
    const performCheck = async () => {
      try {
        if (accounts.length === 0) {
          throw new Error('No accounts available');
        }

        const options = await getFetchOptions(instance, accounts[0]);
        const response = await fetch(`${config.ptaasApi.baseUrl}/UserAccount`, options);

        if (response.ok) {
          const data = await response.json(); // Assuming the response body is JSON
          
          if (data === true) {
            // If the response is valid, log out the user
            
            logout(instance,accounts[0]);
          } else {
            // If the response is invalid, allow the children to render
            setIsInitialized(true);
          }
        } else {
          console.error('Failed to initialize: ', response.statusText);
          setIsInitialized(true); // Allow children to render on error
        }
      } catch (error) {
        console.error('Error fetching initialization data:', error);
        setIsInitialized(true); // Allow children to render on error
      }
    };

    performCheck();
  }, [getFetchOptions, instance, accounts]);

  
  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default AppInitializer;
