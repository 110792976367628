import '../charts.css';
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FadeAlert, LinkButton } from '../Components/Global/Transitions';
import { ChatIcon, LiveIcon, LoaderIcon } from '../Components/Global/Icons';
import {  GetChatByTestId, getPhaseById, getprojectbyidforuser } from '../Services/PtaasApiService';
import Tests from '../Models/Tests';
import Phase from '../Models/Phase';
import ReportChart from '../Components/Reports/ReportChart';
import ReportPhase from '../Components/Reports/ReportPhase';
import ReportScore from '../Components/Reports/ReportScore';
import ReportDistribution from '../Components/Reports/ReportDistribution';



const ReportPhasePage: React.FC = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [score, setScore] = useState(0);
  const [total, setTotal] = useState(0);
  const { instance, accounts } = useMsal();
  const [lowCount, setLowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [highCount, setHighCount] = useState(0);
  const [infoCount, setInfoCount] = useState(0);
  const { id, canopyid, phaseid } = useParams();
  const [phase, setPhase] = useState<Phase>(null);
  const [mediumCount, setMediumCount] = useState(0);
  const [fetchError, setFetchError] = useState(false);
  const [chatid,setChatid]=useState('');
  const [criticalCount, setCriticalCount] = useState(0);

  useEffect(() => {
    async function fetchPhase(): Promise<void> {
      setLoading(true);
      let returnedTest: Tests;
      let returnedPhase: Phase;
      try {
        returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
        returnedPhase = await getPhaseById(parseInt(canopyid), parseInt(phaseid), instance, accounts[0]);
        const returnedid= await GetChatByTestId(instance, accounts[0],returnedTest.id);
        setChatid(returnedid);
        setPhase(returnedPhase);
        setTotal(returnedPhase.findings.length);
        setScore(isNaN(parseInt(returnedPhase?.tester_rating)) ? 0 : parseInt(returnedPhase?.tester_rating));
        setName(returnedTest.projectName + ': Reports (' + returnedPhase.title + ')');
        const low = returnedPhase.findings.filter((finding) => finding.rating === 4).length;
        const info = returnedPhase.findings.filter((finding) => finding.rating === 5).length;
        const high = returnedPhase.findings.filter((finding) => finding.rating === 2).length;
        const medium = returnedPhase.findings.filter((finding) => finding.rating === 3).length;
        const critical = returnedPhase.findings.filter((finding) => finding.rating === 1).length;
        setLowCount(low);
        setInfoCount(info);
        setHighCount(high);
        setMediumCount(medium);
        setCriticalCount(critical);
      } catch {
        setFetchError(true)
      } finally {
        setLoading(false);
      }
    }
    fetchPhase();
  }, [navigate, accounts, instance, phaseid, canopyid,id]);

  return (loading ? <LoaderIcon /> : (
    <Row>
      <Col xl={8} as='h1'>{name}</Col>
      <Col sm={4} className='text-end'>
        <LinkButton to={`/messaging?id=${chatid}`}><ChatIcon />Messaging</LinkButton>
      </Col>
      <Col xl={8}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
        <Alert show variant='info' className='shadow mb-4'>
          <LiveIcon className='text-danger mt-n1' />
          These are live reports and are subject to change.
        </Alert>
        <ReportChart phase={phase} />
        <ReportPhase phase={phase} />
      </Col>
      <Col md={10} lg={8} xl={4}>
        <ReportScore score={score} criticalCount={criticalCount} />
        <ReportDistribution
          total={total}
          low={lowCount}
          info={infoCount}
          high={highCount}
          medium={mediumCount}
          critical={criticalCount}
        />
      </Col>
    </Row>
  ));
};

export default ReportPhasePage;