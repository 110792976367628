import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, Form, InputGroup } from 'react-bootstrap';
import { DownExpandIcon } from '../Global/Icons';
import Question from '../../Models/Question';
import Assignment from '../../Models/Assignment';

interface FileEntry {
  id: string;
  file: File | null;
}

interface FileEntryDetails {
  questionId: string;
  fileName: string;
}

interface SetupQuestionProps {
  question: Question;
  group: 'prereq' | 'scoping';
  assignment?: Assignment[];
  inputvalue: any[];
  onDataChange: (data: any[], id: string) => void;
  onFilesUpdate: (updatedFiles: FileEntry[]) => void;
  filedetails: FileEntryDetails[];
  onValidate: (isValid: boolean) => void; 
}

const SetupQuestion: React.FC<SetupQuestionProps> = ({
  question,
  
  inputvalue,
  onDataChange,
  onFilesUpdate,
  filedetails,
  onValidate,
}) => {
  const [answer, setAnswer] = useState<string>(''); // Initialize the answer state for main question
  const [subAnswers, setSubAnswers] = useState<Record<string, string>>({}); // Sub-question answers state
  const [files, setFiles] = useState<FileEntry[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [show, setShow] = useState(false); // For collapsing/expanding the question
  const [hover, setHover] = useState(false); // For hover state on the header
  const [error, setError] = useState<string>(''); // To show validation errors
 

  // Initialize main question answer from inputvalue (when passed from parent component)
  useEffect(() => {
    const initialAnswer = inputvalue.find((item) => item.spId === question.id)?.answerText || '';
    setAnswer(initialAnswer);
  }, [inputvalue, question.id]);
 
  // Initialize sub-question answers from inputvalue (when passed from parent component)
  useEffect(() => {
    const subAnswersInit: Record<string, string> = {};
    question.subQuestions?.forEach((sub) => {
      const subAnswer =
        inputvalue
          .find((item) => item.spId === question.id)
          ?.subQuestions?.find((subItem) => subItem.subId === sub.id)?.answerText || '';
      subAnswersInit[sub.id] = subAnswer;
    });
    setSubAnswers(subAnswersInit); // Set all sub-answers
  }, [inputvalue, question.id, question.subQuestions]);

  // Load initial file names
  useEffect(() => {
    const names = filedetails
      .filter((detail) => detail.questionId === question.id)
      .map((detail) => detail.fileName);
    setFileNames(names);
  }, [filedetails, question.id]);

  //Handle file changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputId = e.target.id;
    const selectedFile = e.target.files ? e.target.files[0] : null;
  
    // Prevent the function from running if no file is selected
    if (!selectedFile) return;
  
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      const index = updatedFiles.findIndex((entry) => entry.id === inputId);
  
      if (index !== -1) {
        // Update existing file with the same inputId
        updatedFiles[index] = { id: inputId, file: selectedFile };
      } else {
        // Append new file with the given inputId
        updatedFiles.push({ id: inputId, file: selectedFile });
      }
      
     onFilesUpdate(updatedFiles);
console.log(files);
      return updatedFiles; // Return the updated array to set the state
    });
   
  };
  

  
  
  
  // Handle input value change for main question
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => {
    const newValue = event.target.value;
    setAnswer(newValue);

    // Clear validation error when user inputs something
    if (newValue) {
      setError('');
    }

    // Update inputvalue state in parent component for the main question
    const updatedInput = inputvalue.map((item) =>
      item.spId === id ? { ...item, answerText: newValue } : item
    );
    onDataChange(updatedInput, question.questionID);
  };

  // Handle sub-question input changes
  const handleSubQuestionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    subId: string
  ) => {
    const newValue = event.target.value;
    setSubAnswers((prevSubAnswers) => ({
      ...prevSubAnswers,
      [subId]: newValue,
    }));

    // Update inputvalue state in parent component for sub-questions
    const updatedInput = inputvalue.map((item) => {
      if (item.spId === question.id) {
        const updatedSubQuestions = item.subQuestions.map((sub) =>
          sub.subId === subId ? { ...sub, answerText: newValue } : sub
        );
        return { ...item, subQuestions: updatedSubQuestions };
      }
      return item;
    });

    onDataChange(updatedInput, question.questionID);
  };

  // Validate if required question has an answer
  const validateRequired = useCallback(() => {
    if (question.required && !answer) {
      setError('This question is required'); // Set error if the field is required but empty
      onValidate(true); // Notify the parent that validation failed
      return false; // Return false indicating failed validation
    } else {
      setError(''); // Clear the error if validation passes
      onValidate(false); // Notify the parent that validation passed
      
      return true; // Return true indicating successful validation
     
    }
  },[answer,question.required,onValidate]);
  useEffect(() => {
    validateRequired(); // Trigger validation when the component renders or answer changes
  }, [validateRequired,answer]);

  // Render sub-questions based on main question's selected value
  const renderSubQuestions = () => {
    return question.subQuestions?.map((sub) => {
      const isSubQuestionVisible = sub.dependency === answer; // Sub-question appears if dependency matches selected answer
      return isSubQuestionVisible ? (
        <Form.Group key={sub.id} className="pt-3 ps-5">
          <Form.Label as="h5" className="ms-3">
            {sub.subQuestion}
          </Form.Label>
          {['Text', 'Number'].includes(sub.subType) && (
            <Form.Control
              id={sub.id}
              type={sub.subType}
              value={subAnswers[sub.id] || ''} // Use sub-answer from state
              onChange={(e) => handleSubQuestionChange(e, sub.id)}
              className="ms-3"
            />
          )}
          {sub.subType === 'Date' && (
            <Form.Control
              type="date"
              id={sub.id}
              value={subAnswers[sub.id] || ''} // Use sub-answer from state
              onChange={(e) => handleSubQuestionChange(e, sub.id)}
              className="ms-3"
            />
          )}
          {sub.subType === 'Choice' &&
            sub.value.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                label={option}
                value={option}
                name={sub.id}
                checked={subAnswers[sub.id] === option}
                onChange={(e) => handleSubQuestionChange(e, sub.id)}
                className="ms-3"
              />
            ))}
        </Form.Group>
      ) : null;
    });
  };

  // Render input based on question type
  const renderInput = () => {
    switch (question?.questionType) {
      case 'Text':
        return (
          <>
            <Form.Control
              id={question.id}
              type="text"
              value={answer} // Use answer from state
              onChange={(e) => handleInputChange(e, question.id)}
              placeholder="Enter text here"
              className="mt-3 ms-3"
              onBlur={validateRequired} // Validate when the user leaves the field
            />
            {error && <p className="text-danger ms-3 mt-1">{error}</p>} {/* Show error */}
          </>
        );
      case 'Number':
        return (
          <>
            <Form.Control
              id={question.id}
              type="number"
              value={answer}
              onChange={(e) => handleInputChange(e, question.id)}
              placeholder="Enter number here"
              className="mt-3 ms-3"
              onBlur={validateRequired} // Validate when the user leaves the field
            />
            {error && <p className="text-danger ms-3 mt-1">{error}</p>} {/* Show error */}
          </>
        );
      case 'File':
        return (
          <>
            <Form.Control
              id={question.id}
              type="file"
              onChange={handleFileChange}
              className="mt-3 ms-3"
            />
            {fileNames.length > 0 && (
              <div className="mt-2 ms-3">
                {fileNames.map((name, index) => (
                  <div key={index} style={{ color: 'red' }}>{name}</div>
                ))}
              </div>
            )}
            {error && <p className="text-danger ms-3 mt-1">{error}</p>} {/* Show error */}
          </>
        );
      case 'Choice':
        return question.choiceOptions.map((value, index) => (
          <Form.Check
            key={index}
            type="radio"
            label={value}
            value={value}
            name={question.id}
            id={question.id}
            checked={answer === value}
            onChange={(e) => handleInputChange(e, question.id)}
            style={{
              display: question.choiceOptions.length < 3 ? 'inline-block' : 'block',
              marginRight: '3rem',
            }}
            className="mt-3 ms-3" // Add margin-top and margin-start for spacing
            onBlur={validateRequired} // Validate when the user leaves the field
          />
        ));
      case 'Date':
        return (
          <>
            <Form.Control
              type="date"
              id={question.id}
              value={answer}
              onChange={(e) => handleInputChange(e, question.id)}
              className="mt-3 ms-3"
              onBlur={validateRequired} // Validate when the user leaves the field
            />
            {error && <p className="text-danger ms-3 mt-1">{error}</p>} {/* Show error */}
          </>
        );
      case 'FileText':
        return (
          <>
            <InputGroup className="mt-3 ms-3">
              <Form.Control
                as="textarea"
                id={question.id}
                value={answer}
                onChange={(e) => handleInputChange(e, question.id)}
                placeholder="Enter text here"
              />
            </InputGroup>
            <InputGroup className="mt-3 ms-3">
              <Form.Control
                type="file"
                id={question.id}
                onChange={handleFileChange}
              />
            </InputGroup>
            {fileNames.length > 0 && (
              <div className="mt-2 ms-3">
                {fileNames.map((name, index) => (
                  <div key={index} style={{ color: 'red' }}>{name}</div>
                ))}
              </div>
            )}
            {error && <p className="text-danger ms-3 mt-1">{error}</p>} {/* Show error */}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Form.Group className="px-2 mb-4 pb-1">
      <div className="ms-n4 mb-1 mt-n3 py-2 ps-2 setup">
        <Form.Label
          as="h4"
          onClick={() => setShow(!show)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={`
            px-3 py-1 m-0 border-2 d-inline-block pe-link transition 
            ${hover || show ? 'form-colors border ' : 'bg-white border border-white rounded '}
            ${hover && !show ? ' rounded ' : ''}
            ${show ? ' w-100 border-bottom-0 rounded-top ' : ''}
          `}
        >
          {question.order}. <span className="me-2">{question.question}</span>
          <DownExpandIcon
            width={14}
            style={{ marginTop: -5 }}
            className={`ms-2 transition ${show ? 'text-primary-emphasis flip-180' : hover ? 'text-primary-emphasis' : 'text-white'}`}
          />
        </Form.Label>
        <Collapse in={show}>
          <div className="px-4 pb-3 form-colors border border-2 border-top-0 rounded-bottom">
            <p className="m-n2 mb-0 py-2 text-dark">{question?.description || 'No description available.'}</p>
          </div>
        </Collapse>
        {renderInput()}
        {renderSubQuestions()}
      </div>
    </Form.Group>
  );
};

export default SetupQuestion;

