import React from 'react';
import { Accordion } from 'react-bootstrap';
import { CountUpNumber, FadeAccordion } from '../Global/Transitions';
import ScoreProps from '../../Utilities/ScoreProps';



interface ReportScoreProps {
  score?: number;
  criticalCount?: number;
}

const ReportScore: React.FC<ReportScoreProps> = ({ score, criticalCount }) => {
  const props = ScoreProps[score] || ScoreProps.default;

  return (
    <FadeAccordion defaultActiveKey='default'>
      <Accordion.Item eventKey='default'>
        <Accordion.Button as='h3' className='bg-white'>Tester Rating</Accordion.Button>
        <Accordion.Body>
          <svg className='w-100 h-auto' xmlns='http://www.w3.org/2000/svg' width='452' height='196' viewBox='0 0 452 196'>
            <defs>
              <clipPath id='a'>
                <path d='M172.371 98.758a18.928 18.928 0 0 1-.611-5.059 76.562 76.562 0 0 0-74.806-74.615h-3.083a76.543 76.543 0 0 0-74.8 74.567 18.88 18.88 0 0 1-.611 5.059 9.545 9.545 0 0 1-17.97-.076 18.136 18.136 0 0 1-.468-5.651 95.538 95.538 0 0 1 71.255-89.84 94.288 94.288 0 0 1 48.28 0 95.538 95.538 0 0 1 71.274 89.83 18.059 18.059 0 0 1-.468 5.66 9.106 9.106 0 0 1-8.915 6.309 9.412 9.412 0 0 1-9.068-6.242Z' transform='translate(-2 0)' />
              </clipPath>
            </defs>
            <g className={props.color}>
              <g fill='currentColor' fillOpacity='.03' stroke='currentColor' strokeOpacity='.1'>
                <rect width='270' height='196' rx='11' stroke='none' />
                <rect width='269' height='195' x='.5' y='.5' rx='10.5' fill='none' />
              </g>
              <g transform='translate(42 29)' clip-path='url(#a)'>
                <circle className='rating' strokeDasharray={(score * 36) + 47 + ' 304'} fill='none' stroke='currentColor' strokeWidth='100' cx='52.5' cy='52.5' r='52.5' transform='rotate(120 61 86)' />
                <circle className='dark-white' fill='black' fillOpacity='.05' cx='95' cy='95' r='95' transform='translate(-2 0)' />
              </g>
              <text className='svg-text' fill='currentColor' fontSize='23'  dominantBaseline='middle' textAnchor='middle' transform='translate(135 124)'>{score}/5</text>
              <text className='svg-text' fontSize='18' fill='var(--bs-dark)' transform='translate(136 165)' dominantBaseline='middle' textAnchor='middle'>
                <tspan className='dark-light'>Your security score is </tspan>
                <tspan fill='currentColor'>{props.name}</tspan>
              </text>
            </g>
            <g className={criticalCount > 0 ? 'text-danger' : 'text-success'} transform='translate(0 0)'>
              <g fill='currentColor' fillOpacity='.03' stroke='currentColor' strokeOpacity='.1' transform='translate(293 0)'>
                <rect width='159' height='196' rx='11' stroke='none' />
                <rect width='158' height='195' rx='10.5' fill='none' x='.5' y='.5' />
              </g>
              <text className='svg-text' fill='currentColor' fontSize='18' dominantBaseline='middle' textAnchor='middle' transform='translate(373 165)'>Critical Issues</text>
              <g fill='currentColor' stroke='currentColor' fillOpacity='0.1' strokeOpacity='0.2' transform='translate(320 29)' >
                <circle cx='52.5' cy='52.5' r='52.5' stroke='none' />
                <circle cx='52.5' cy='52.5' r='52' fill='none' />
              </g>
              <circle className='dark-none' fill='#fff' stroke='currentColor' strokeWidth='3' cx='42.5' cy='42.5' r='42.5' transform='translate(330 39)' />
              <text x='0' y='0' className='svg-text' dominantBaseline='middle' textAnchor='middle' fill='currentColor' fontSize='28' transform='translate(372.25 87.25)'>
                <CountUpNumber number={criticalCount} />
              </text>
            </g>
          </svg>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ReportScore;