import React from 'react';
import { StarIcon } from './Icons';
import { FadeIn, FloatingField } from './Transitions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface FeedbackProps {
  rating: number;
  comment: string;
  setRating: (rating: number) => void;
  setComment: (comment: string) => void;
}

const Feedback: React.FC<FeedbackProps> = ({ rating, comment, setRating, setComment }) => {
  const toolTip = (number: number) => <Tooltip>{number + ' star' + (number > 1 ? 's' : '')}</Tooltip>;

  return (
    <FadeIn>
      <div className='pb-4'>
        {[1, 2, 3, 4, 5].map((index) => (
          <OverlayTrigger key={index} overlay={toolTip(index)}>
            <span className='pe-link' onClick={() => setRating(index)}>
              <StarIcon width={30} isSwitch={index <= rating} />
            </span>
          </OverlayTrigger>
        ))}
        <FloatingField 
          label='Leave a comment' 
          className='mt-4 w-80 mx-auto' 
          value={comment} 
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)} 
        />
      </div>
    </FadeIn>
  );
};

export default Feedback;
