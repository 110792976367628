import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Accordion } from 'react-bootstrap';
import { getTestType } from '../../Services/PtaasApiService';
import { PlusIcon, CheckIcon, TestCountIcon } from '../Global/Icons';
import { FadeAccordion, FadeModal, OverlayLink } from '../Global/Transitions';
import Tests from '../../Models/Tests';
import ProjectEdit from '../Popups/EditProject';



interface TestTypeProps {
  testType: string;
  isDisabled?: boolean;
}

interface ProjectTestTypesProps {
  test: Tests;
  testTypes: string[];
  isDisabled?: boolean;
  OnProjectTestTypeChange?:()=>void;
}

const ProjectTestType: React.FC<TestTypeProps> = ({ testType, isDisabled }) => {
  const [name, setName] = useState('');
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const fetchTestType = async (): Promise<void> => {
      try {
        const returnedType = (await getTestType(instance, accounts[0])).find(type => type.id === testType);
        setName(returnedType?.testTypeName);
      } catch { }
    };
    fetchTestType();
  }, [accounts, instance, testType]);
  
  return <p><CheckIcon className='text-metal'/>{name}</p>
};


const ProjectTestTypes: React.FC<ProjectTestTypesProps> = ({ test, testTypes, isDisabled ,OnProjectTestTypeChange}) => {
  const [show, setShow] = useState(false);
  const handleHide = () => {setShow(false);OnProjectTestTypeChange();};
  const [complete, setComplete] = useState(false);
  const handleComplete = () => setComplete(true);
  const handleShow = () => { setComplete(false); setShow(true) };

  return (
    <FadeAccordion defaultActiveKey='0'>
      <Accordion.Item className='mb-4' eventKey={isDisabled ? '1' : '0'}>
        <Accordion.Button as='h3'>
          Selected Test Types
          <TestCountIcon number={testTypes.length} className={'ms-2 ' + (isDisabled ? 'text-dark' : 'text-secondary')} />
        </Accordion.Button>
        <Accordion.Body>
          {testTypes.map(testType => <ProjectTestType key={testType} isDisabled={isDisabled} testType={testType} />)}
          <OverlayLink
            placement='left'
            onClick={handleShow}
            disabled={isDisabled}
            className={'bold ' + (isDisabled ? 'text-dark' : 'text-primary')}
            body={<><PlusIcon />Add Test Types</>}
            message='Sorry. You can no longer add test types to this project.'
          />
          <FadeModal show={show} onHide={handleHide} title={complete ? '' : 'Edit Project'}>
            <ProjectEdit action='edit' test={test} close={handleHide} complete={handleComplete} />
          </FadeModal>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ProjectTestTypes;