import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Menu from './Menu';
import Crumbs from './Crumbs';
import Notifications from './Notifications';



interface HeaderProps {
  data_bs_theme: string;
}

const Header: React.FC<HeaderProps> = ({ data_bs_theme }) => {
  const Gradient: React.CSSProperties = {
    top: 0,
    left: 0,
    right: 0,
    content: '',
    width: '100%',
    position: 'absolute',
    transition: 'all .2s',
    background: 'var(--gradient)',
    height: 'calc(var(--gradient-height) + .025rem)',
  };

  return (
    <>
      <div style={Gradient} />
      <div className='w-100' data-bs-theme={data_bs_theme}>
        <header className='w-100 bg-basic position-fixed top-0 left-0' >
          <Container fluid>
            <Row>
              <Col xs={6} sm={7} md={8} lg={10} className='my-auto'>
                <Crumbs />
              </Col>
              <Col xs={6} sm={5} md={4} lg={2} className='text-end'>
                <Notifications />
                <Menu />
              </Col>
            </Row>
          </Container>
        </header>
      </div>
    </>
  );
};

export default Header;