
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { TestCountIcon } from '../../Global/Icons';
import { LinkBadge } from '../../Global/Transitions';
import Phase from '../../../Models/Phase';
import Finding from '../../../Models/Finding';
import PhaseProps from '../../../Utilities/PhaseProps';



interface PhaseRowProps {
  phase: Phase;
}

const PhaseRow: React.FC<PhaseRowProps> = ({ phase }) => {
  
  const caseCount = (findings: Finding[], rating: number): number => findings.filter((f) => f.rating === rating).length;
  const status = (phase.status && phase.status === "Completed") ? "Completed" : "In Progress";
  const props = PhaseProps[status] || PhaseProps.default;
  return (
    <tr key={phase.id}>
      <td className='text-metal h5 m-0'>{phase.title}</td>
      <td width='150'><LinkBadge bg={props.bg} text={props.text} border={props.border}>{status}</LinkBadge></td>
      <td width='160' className={'h5 m-0 ' + (caseCount(phase.findings, 1) > 0 ? 'text-danger' : 'text-gray')}>
        <TestCountIcon style={{ marginTop: -3, marginRight: 3 }} width={22} number={caseCount(phase.findings, 1)} />
        Critical Issues
      </td>
      <td width='120'><ProgressBar now={100} /></td>
    </tr>
  );
};

export default PhaseRow;