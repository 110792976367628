import React, { useEffect, useState } from "react";
import { Col, Row, Accordion } from "react-bootstrap";
import { LoaderIcon } from "../Components/Global/Icons";
import { FadeAccordion, FadeAlert } from "../Components/Global/Transitions";
import FAQ from "../Models/FAQ";
import RecentActivity from "../Components/Global/RecentActivity";
import { getFAQs } from "../Services/PtaasApiService";
import { useMsal } from "@azure/msal-react";




const FAQsPage: React.FC = () => {
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const fetchFAQs = async (): Promise<void> => {
      setLoading(true);
      let returnedFAQs:FAQ[]
      try {
        returnedFAQs=await getFAQs(instance, accounts[0]); 
        setFaqs(returnedFAQs);
      } catch {
        setFetchError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchFAQs();
  }, [instance,accounts]);

  return (loading ? <LoaderIcon /> : (
    <Row>
      <Col sm={12} as='h1'>FAQs</Col>
      <Col xl={9}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
        <FadeAccordion defaultActiveKey="0">
          {faqs.map((faq) => (
            <Accordion.Item key={faq.id} eventKey={faq.id}>
              <Accordion.Button as='h3'>{faq.question}</Accordion.Button>
              <Accordion.Body>{faq.answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </FadeAccordion>
      </Col>
      <Col xl={3}>
        <RecentActivity />
      </Col>
    </Row>
  ));
};

export default FAQsPage;