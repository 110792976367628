import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Crumb, FadeIn } from './Transitions';
import { useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { getPhaseById, getprojectbyidforuser } from '../../Services/PtaasApiService';
import Tests from '../../Models/Tests';
import Phase from '../../Models/Phase';





const Crumbs: React.FC = () => {
  const [phase, setPhase] = useState('');
 
  const { instance, accounts } = useMsal();
  const [project, setProject] = useState('');
  const [loading, setLoading] = useState(false);
  const { id, canopyid, phaseid, clientid, typeid, findid } = useParams();

  useEffect(() => {
    const fetchBreadcrumbs = async (): Promise<void> => {
      try {
        setLoading(true);
        let returnedTest: Tests;
        let returnedPhase: Phase;
        
        if (id) {
          returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
          setProject(returnedTest?.projectName || null);
        }
        if (phaseid) {
          returnedPhase = await getPhaseById(parseInt(canopyid), parseInt(phaseid), instance, accounts[0]);
          setPhase(returnedPhase?.title || null);
        }
        
      } catch (error) {
        console.error('Error fetching data in Breadcrumbs:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBreadcrumbs();
  }, [accounts, instance, id, canopyid, phaseid, clientid ]);

  return (loading ? null : (
    <FadeIn>
      <Breadcrumb>
        <BreadcrumbItem className='pe-none'>Luxis</BreadcrumbItem>
        <Crumb to={'/faqs'}>FAQs</Crumb>
        <Crumb to={'/messaging'}>Messaging</Crumb>
        <Crumb to={'/dashboard'}>Dashboard</Crumb>
        <Crumb to={'/quotes'}>Quote Requests</Crumb>
        <Crumb to={'/quotes/'+id}>Create Quote</Crumb>
        <Crumb to={'/preferences'}>Preferences</Crumb>
        <Crumb to={'/projects'}>Projects</Crumb>
        {canopyid ? null : <Crumb to={'/projects/' + id}>{project}</Crumb>}
        {canopyid ? <Crumb to={'/projects/' + id + ('/' + canopyid)}>{project}</Crumb> : null}
        <Crumb to={'/projects/' + id + '/setup'}>Setup</Crumb>
        <Crumb to={'/projects/' + id + '/prereqs'}>Prerequisites</Crumb>
        <Crumb to={'/projects/' + id + '/quote'}>Quote</Crumb>
        <Crumb to={'/projects/' + id + '/' + canopyid + '/reports'}>Reports</Crumb>
        <Crumb to={'/projects/' + id + '/' + canopyid + '/reports/' + phaseid}>{phase}</Crumb>
        <Crumb to={'/projects/' + id + '/' + canopyid + '/reports/' + phaseid + '/finding/' + findid}>Findings</Crumb>
        <Crumb to={'/testtypes'}>Test Types</Crumb>
        <Crumb to={'/testtypes/' + typeid + 'prereqs'}>Prerequisites</Crumb>
        <Crumb to={'/testtypes/' + typeid + 'scoping'}>Scoping Questions</Crumb>
        <Crumb to={'/clients'}>Clients</Crumb>
        
      </Breadcrumb>
    </FadeIn>
  ));
};

export default Crumbs;