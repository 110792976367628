import React, { useEffect, useState } from "react";
import { Accordion, Col, Row, Alert } from "react-bootstrap";
import { LoaderIcon } from "../Components/Global/Icons";
import { FadeAccordion } from "../Components/Global/Transitions";
import Message from "../Models/Message";
import Messaging from "../Components/Global/Messaging";
import {  getMessagesFromJira } from "../Services/PtaasApiService";
import { useMsal } from "@azure/msal-react";


const MessagingPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
 

  const { instance, accounts } = useMsal();

  useEffect(() => {
    const fetchMessages = async (): Promise<void> => {
      if (!accounts || accounts.length === 0) return;

      try {
        setLoading(true);
        const returnedChats = await getMessagesFromJira(instance, accounts[0]);
        setMessages(returnedChats);
        
        if (returnedChats.length === 0) {
          setFetchError("No messages found.");
        } else {
          setFetchError(null);
        }
      } catch (error) {
        setFetchError("Failed to fetch messages. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [instance, accounts]);

  if (loading) {
    return <LoaderIcon />;
  }

  return (
    <Row>
      <Col sm={12} as="h1">
        Messaging
      </Col>
      <Col sm={12}>
        {fetchError ? (
          <Alert variant="warning" onClose={() => setFetchError(null)} dismissible>
            {fetchError}
          </Alert>
        ) : messages.length === 0 ? (
          <Alert variant="info">No messages found.</Alert>
        ) : (
          <FadeAccordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="overflow-hidden position-relative">
              <Accordion.Body className="gradient p-0 m-0">
                <Messaging messages={messages}  />
              </Accordion.Body>
            </Accordion.Item>
          </FadeAccordion>
        )}
      </Col>
    </Row>
  );
};

export default MessagingPage;
