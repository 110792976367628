import React from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { LongArrowIcon } from '../Global/Icons';
import { Accordion, Row, Col } from 'react-bootstrap';
import { FadeAccordion, AnimatedProgress, LinkButton } from '../Global/Transitions';
import Tests from '../../Models/Tests';
import PhaseList from '../Tables/PhaseList';
import TestProps from '../../Utilities/TestProps';
import Project from '../../Models/Project';




interface ProjectOverviewProps {
  test: Tests;
  testTypes: string[];
  projectdata?: Project;
}

const ProjectOverview: React.FC<ProjectOverviewProps> = ({ test, projectdata }) => {
  const { canopyid } = useParams();
  const { nextStatus, progress } = TestProps[test.status];
  const show = projectdata?.startDate == null ? true : false;
 




  

  // Function to format the date
  const handleFormatDate = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Normalize today's date for comparison

    if (!dateString) {
      return 'Not yet confirmed';
    }

    const date = new Date(dateString);

    

    return format(date, 'dd/MM/yyyy');// Customize format as necessary
  };


  return (
    <FadeAccordion defaultActiveKey='1'>
      <Accordion.Item eventKey='1'>
        <Accordion.Button as='h3' className='accordion-static'>Progress</Accordion.Button>
        <Accordion.Body>
          <AnimatedProgress initialProgress={progress} height='12px' className='my-2' />
          <h6 className='d-inline text-uppercase text-success'>{test.status}</h6>
          <h6 className='d-inline text-uppercase text-gray'><LongArrowIcon className='ms-1 me-2' />{nextStatus}</h6>
          {canopyid ?
            <PhaseList /> : null}
          {canopyid ?
            <LinkButton to='reports'>View Reports</LinkButton> : null}
          <hr className='my-4' />
          <Row className='mb-n3'>
            <Col md={6} as='p'>
              <strong>Project Name</strong>
              {test?.projectName}
            </Col>
            <Col md={6} as='p'>
              <strong>Project Owner</strong>
              {test?.projectOwner}
            </Col>
            {show ?
              <Col md={6} as='p'>
                <strong>Submission Date</strong>
                {format(new Date(), 'dd/MM/yyyy')}
              </Col> :
              <><Col md={6} as='p'>
                <strong>Start Date</strong>
                {handleFormatDate(projectdata?.startDate)}
              </Col><Col md={6} as='p'>
                  <strong>End Date</strong>
                  {handleFormatDate(projectdata?.endDate)}
                </Col></>}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ProjectOverview;