import React, { useState } from 'react';
import { format } from 'date-fns';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { SendIcon, SuccessIcon } from '../Global/Icons';
import { FadeAccordion, FadeModal } from '../Global/Transitions';
import {    putprojectdetails } from '../../Services/PtaasApiService';
import { Button, Accordion, Row, Col, Modal } from 'react-bootstrap';
import Tests from '../../Models/Tests';



interface CommercialProps {
  test: Tests,
  status: string,
  onStageChange: (newStage: number) => void;
  userinfo?:User;
}

const SetupCommercials: React.FC<CommercialProps> = ({ status, test, onStageChange,userinfo }) => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const { instance, accounts } = useMsal();
  const handlePrev = async () => onStageChange(1);
  const handleNext = async () => onStageChange(3);
  const [requestSent, setRequestSent] = useState(false);
  const formattedDate = format(new Date(), 'MMMM dd, yyyy');
  const handleShow = () => setShow(true), handleHide = () => setShow(false);
  
  
  const handleSubmit = async () => {
    try {
      
      await putprojectdetails(instance, accounts[0], id, 'Quote Requested', test.projectName, test.projectOwner, test.owneremail, test.accepteddate, test.availabledate, test.comment, test.testType, test.assignment);
      setRequestSent(true);
      
  } catch (error) {
      console.error("An error occurred:", error);
  }
   
   
  };

  return (
    <FadeAccordion defaultActiveKey='1'>
      <Accordion.Item eventKey='1'>
        <Accordion.Button as='h3' className='accordion-static'>Commercials</Accordion.Button>
        <Accordion.Body as={Row}>
          <Col md={6}>
            <b>Project Name</b>
            <p className='mt-2'>{test.projectName}</p>
          </Col>
          <Col md={6}>
            <b>Project Owner</b>
            <p className='mt-2'>{test.projectOwner}</p>
          </Col>
          <Col md={6}>
            <b>Submission Date</b>
            <p className='mt-2'>{formattedDate}</p>
          </Col>
          <Col sm={12} className='mb-2'>
            <Button variant='outline-primary' onClick={handlePrev}>Previous</Button>
            <Button className='ms-3' onClick={handleShow}>Submit</Button>
            <FadeModal show={show} onHide={handleHide}>
              {requestSent ?
                <Modal.Body className='text-center my-3 pt-3 px-xl-5'>
                  <SuccessIcon />
                  <h1 className='m-0 pb-2'>Quote Requested. Thank you!</h1>
                  <p>You will receive the quote in 1-2 business days.</p>
                  <Button onClick={handleNext}>Continue</Button>
                </Modal.Body>
                :
                <Modal.Body className='text-center my-3 pt-3 px-xl-5'>
                  <SendIcon />
                  <h1 className='m-0 pb-2'>Request quote?</h1>
                  <p className='px-5'>Once you submit your request, you will not able to add more test types to this project.</p>
                  <Button variant='outline-primary' onClick={handleHide}>Cancel</Button>
                  <Button className='ms-3' onClick={handleSubmit}>Submit</Button>
                </Modal.Body>
              }
            </FadeModal>
          </Col>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default SetupCommercials;