import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { DownloadIcon } from '../Global/Icons';
import { LinkBadge } from '../Global/Transitions';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Tests from '../../Models/Tests';
import TestType from '../../Models/TestType';
import lineItem from '../../Models/LineItems';



interface ProjectQuotePDFProps {
  test: Tests;
  lineItems: lineItem[];
  testTypes?: TestType[];
  client?:string;
}

const ProjectQuotePDF: React.FC<ProjectQuotePDFProps> = ({ test, lineItems ,client}) => {
  const [calculatedTax, setCalculatedTax] = useState(0);
  const [calculatedGross, setCalculatedGross] = useState(0);
  const [calculatedTotal, setCalculatedTotal] = useState(0);

  useEffect(() => {
    // Calculate total amount
    const newTotalAmount = lineItems.reduce((total, item) => total + item.amount, 0);
    setCalculatedGross(parseFloat(newTotalAmount.toFixed(2)));

    // Calculate tax (20% of the total amount)
    const newTax = parseFloat((0.2 * newTotalAmount).toFixed(2));
    setCalculatedTax(newTax);

    // Calculate total amount with tax
    const newTotalAmountWithTax = parseFloat((newTotalAmount + newTax).toFixed(2));
    setCalculatedTotal(newTotalAmountWithTax);
  }, [lineItems]);

  const styles = StyleSheet.create({
    page: {
      padding: 15,
      flexDirection: 'row',
      fontFamily: 'Helvetica',
      backgroundColor: '#F2F2F2',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      fontSize: 16,
      marginBottom: 30,
      color: '#333333',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    clientInfo: {
      fontSize: 11,
      marginBottom: 10,
      color: '#555555',
    },
    invoiceDetails: {
      fontSize: 11,
      marginBottom: 10,
      color: '#555555',
    },
    table: {
      width: '100%',
      marginTop: 10,
    },
    tableHeader: {
      fontSize: 11,
      marginTop: 6,
      marginBottom: 6,
      color: '#555555',
    },
    tableRow: {
      padding: 3,
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#dedede',
      justifyContent: 'space-between',
    },
    topTableHeader: {
      fontSize: 12,
      marginTop: 4,
      marginBottom: 4,
      color: '#555555',
      fontWeight: 'bold'
    },
    bottomTable: {
      width: '100%',
      marginTop: 80,
    },
    bottomTableHeader: {
      fontSize: 11,
      marginTop: 4,
      marginBottom: 4,
      color: '#555555',
    },
    bottomTableRow: {
      padding: 3,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    totals: {
      fontSize: 11,
      marginTop: 10,
      color: '#333333',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    footer: {
      left: 10,
      right: 10,
      bottom: 10,
      fontSize: 8,
      color: '#777777',
      position: 'absolute',
    },
    totalValue: {
      textAlign: 'right',
    },
  });

  const PDFContent = () => (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Project Quote</Text>
          <View style={styles.clientInfo}>
            <Text style={styles.bottomTableHeader}>Client: {client}</Text>
            <Text style={styles.bottomTableHeader}>Project: {test?.projectName}</Text>
          </View>
          <Image src="/test-image.png" style={{ width: 50, height: 50 }} />
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.topTableHeader}>Line Item</Text>
              <Text style={styles.topTableHeader}>Amount</Text>
            </View>
            {lineItems?.map((lineItem) => (
              <View style={styles.tableRow} key={lineItem.title}>
                <Text style={styles.tableHeader}>{lineItem.title}</Text>
                <Text style={styles.tableHeader}>{'£' + lineItem.amount}</Text>
              </View>
            ))}
          </View>
          <View style={styles.bottomTable}>
            <View style={styles.bottomTableRow}>
              <Text style={styles.bottomTableHeader}></Text>
              <Text style={styles.bottomTableHeader}>Gross:  {'£' + calculatedGross.toFixed(2)}</Text>
            </View>
            <View style={styles.bottomTableRow}>
              <Text style={styles.bottomTableHeader}></Text>
              <Text style={styles.bottomTableHeader}>Tax:    {'£' + calculatedTax.toFixed(2)}</Text>
            </View>
            <View style={styles.bottomTableRow}>
              <Text style={styles.bottomTableHeader}></Text>
              <Text style={styles.bottomTableHeader}>Total:  {'£' + calculatedTotal.toFixed(2)}</Text>
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={{ textAlign: 'center' }}>Prism Infosec Ltd. All rights reserved.</Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink document={<PDFContent />} fileName='quote.pdf' className='position-relative'>
      <LinkBadge className='ms-3 mb-1'><DownloadIcon />Download PDF</LinkBadge>
    </PDFDownloadLink>
  );
};

export default ProjectQuotePDF;