import React, { useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { LoaderIcon } from '../Global/Icons';
import {  Form } from 'react-bootstrap';
import {  getFilesByProjectTicketnumber, getprojectbyidforuser } from '../../Services/PtaasApiService';
import Tests from '../../Models/Tests';
import Question from '../../Models/Question';
import SetupQuestion from './SetupQuestion';

interface SetupQuestionsProps {
  questions: Question[];
  group: 'prereq' | 'scoping';
  onInputChange: (updatedInputValue: any[], assignmentId: string) => void;
  OnfileChange:(files:FileEntry[])=>void;
  handleValidate:(Isvalid:boolean)=>void;
}

interface FileEntry {
  id: string;
  file: File | null;
}

interface FileEntryDetails {
  questionId: string;
  fileName: string;
}

const SetupQuestionList: React.FC<SetupQuestionsProps> = ({ questions, group,onInputChange,OnfileChange,handleValidate }) => {
  const { id } = useParams();
  const { instance, accounts } = useMsal();
  const [fileDetails, setFileDetails] = useState<FileEntryDetails[]>([]);
  const [test, setTest] = useState<Tests>();
  const [loading, setLoading] = useState(false);
 
  const [inputValue, setInputValue] = useState<any[]>([]); // Managing form data for questions and sub-questions
 
  
  
  // Fetch project details by ID
  const fetchProjects = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
      setTest(returnedTest);
    } catch (error) {
      console.error('Error fetching project:', error);
    } finally {
      setLoading(false);
    }
  }, [accounts, instance, id]);

  // Fetch file details
  const fetchFileDetails = useCallback(async (): Promise<void> => {
    if (!test?.ticketno) return;
    setLoading(true);
    try {
      const returnedFiles = await getFilesByProjectTicketnumber(instance, accounts[0], test.ticketno);
      setFileDetails(returnedFiles);
    } catch (error) {
      console.error('Error fetching file details:', error);
    } finally {
      setLoading(false);
    }
  }, [accounts, instance, test?.ticketno]);




  // Populate input values for questions and sub-questions on initial fetch
  useEffect(() => {
    if (test && questions.length > 0) {
      const initialInputValues = questions.map((item) => {
        const assignment = test.assignment.find((assgn) => assgn.assignmentId === item.questionID);
       
        
        const answerText = assignment?.[group]?.find((ans) => ans.spId === item.id)?.answerText || '';
        return {
          spId: item.id,
          question: item.question,
          answerText: answerText,
          subQuestions: item.subQuestions ? item.subQuestions.map((subItem) => ({
            subId: subItem.id,
            question: subItem.subQuestion,
            answerText: assignment?.[group]?.find((ans) => ans.spId === item.id)?.subQuestions?.find(sub => sub.subId === subItem.id)?.answerText || ''
          })) : []
        };
      });
      setInputValue(initialInputValues);
    }
  }, [questions,group,test]);

  const handleInputChange = (updatedValue: any[]) => {
    setInputValue(updatedValue);
    onInputChange(updatedValue, questions[0]?.questionID); // Report changes back to the parent
  };


  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if (test?.ticketno) {
      fetchFileDetails();
    }
  }, [test, fetchFileDetails]);



  return (
    <Form className='pe-lg-5 pe-xl-6'>
      {loading ? (
        <LoaderIcon />
      ) : (
        questions.map((question, index) => (
          <SetupQuestion
            key={index}
            group={group}
            question={question}
            onDataChange={handleInputChange} // This updates inputValue, not initialInputValue
            inputvalue={inputValue} // Ensure inputValue is passed, not initialInputValue
            assignment={test?.assignment}
            onFilesUpdate={OnfileChange}
            filedetails={fileDetails}
            onValidate={handleValidate}
          />
        ))
      )}
    
    </Form>

  );
};

export default SetupQuestionList;
