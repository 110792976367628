import React, { useCallback, useEffect, useState } from 'react';
import {   Col, Row } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import {   LoaderIcon } from '../Components/Global/Icons';
import { FadeAlert } from '../Components/Global/Transitions';
import {   getprojectforuser } from '../Services/PtaasApiService';
import Tests from '../Models/Tests';
import ProjectsList from '../Components/Tables/ProjectsList';
import RecentActivity from '../Components/Global/RecentActivity';


const DashboardPage: React.FC = () => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [tests, setTests] = useState<Tests[]>([]);
  const [fetchError, setFetchError] = useState(false);
 

  const fetchProjects = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const returnedTests = await getprojectforuser(instance, accounts[0]);
      setTests(returnedTests);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  }, [instance, accounts]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  

 

  return loading ? (
    <LoaderIcon />
  ) : (
    <Row>
      <Col sm={12} as='h1'>Dashboard</Col>
     
      <Col xl={9}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
       
          <ProjectsList tests={tests} OnProjectChange={fetchProjects} />
        
      </Col>
      <Col xl={3}>
        <RecentActivity />
      </Col>
    </Row>
  );
};

export default DashboardPage;
