import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { exportAsCsv, exportAsJson, exportAsPdf } from '../../Models/Export';
import Phase from '../../Models/Phase';
import Project from '../../Models/Project';



interface ExportButtonProps {
	data: Project | Phase;
	errorCallback?: (message: string) => void
}

const ExportButton: React.FC<ExportButtonProps> = ({ data, errorCallback }) => {
	const { instance, accounts } = useMsal();
	const [isLoading, setIsLoading] = useState(false);

	const downloadJSON = () => { exportAsJson(data) };
	const downloadCSV = () => { exportAsCsv(data) };
	const downloadPDF = async () => {
		setIsLoading(true);
		try {
			await exportAsPdf(data, instance, accounts[0]);
		} catch {
			errorCallback('There was an error fetching your PDF report');
		}
		setIsLoading(false);
	};

	return (
		<DropdownButton title={isLoading ? <><Spinner as='span' size='sm' animation='border' />  Loading</> : <>Download  </>}>
			<Dropdown.Item onClick={downloadPDF}  className='bg-light bold text-center pt-3 ms-n3 rounded-top mt-1'>Download PDF</Dropdown.Item>
			<Dropdown.Item onClick={downloadCSV}  className='bg-light bold text-center py-3 ms-n3'>Download CSV</Dropdown.Item>
			<Dropdown.Item onClick={downloadJSON} className='bg-light bold text-center pb-3 ms-n3 rounded-bottom'>Download JSON</Dropdown.Item>
		</DropdownButton>
	);
};

export default ExportButton;