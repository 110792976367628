import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { GetProfilePicture } from '../../Services/PtaasApiService';
import { useMsal } from '@azure/msal-react';

type ProfileContextType = {
  profilePicUrl: string;
  setProfilePicUrl: (url: string) => void;
};

const defaultState: ProfileContextType = {
  profilePicUrl: '',
  setProfilePicUrl: () => {}
};

// Creating the context with default state
const ProfileContext = createContext<ProfileContextType>(defaultState);

type ProfileProviderProps = {
  children: ReactNode;
};

export const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const [profilePicUrl, setProfilePicUrl] = useState<string>('');
  const { instance,accounts } = useMsal();

  useEffect(() => {
    const loadProfilePic = async () => {
        const imageData =await GetProfilePicture(instance, accounts[0]);
        const imageUrl = URL.createObjectURL(imageData);// Create a URL for the Blob
      setProfilePicUrl(imageUrl);
    };
    loadProfilePic();
  }, [instance,accounts]);

  return (
    <ProfileContext.Provider value={{ profilePicUrl, setProfilePicUrl }}>
      {children}
    </ProfileContext.Provider>
  );
};



// Export the use of context
export const useProfileContext = () => useContext(ProfileContext);