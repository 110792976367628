import React from 'react';
import { CountUpNumber } from './Transitions';



interface IconProps extends React.HTMLAttributes<SVGSVGElement> {
  fill?: string;
  text?: string;
  width?: number;
  number?: number;
  stroke?: string;
  viewBox?: string;
  isSwitch?: boolean;
  isGradient?: boolean;
  strokeWidth?: string;
  onClick?: () => void;
  unread?:number;
}

export const Icon: React.FC<IconProps> = ({ className, children, style, width, viewBox, fill, stroke, strokeWidth, onClick, onMouseEnter, onMouseLeave }) => (
  <svg className={className} width={width} style={style} stroke={stroke} strokeWidth={strokeWidth} viewBox={viewBox} fill={fill} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} xmlns='http://www.w3.org/2000/svg'>{children}</svg>
)

export const ExpandIcon: React.FC<IconProps> = ({ className, style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 24 24' fill='none'><path d='M3 21L21 3M3 21h4.5M3 21v-4.5M21 3h-4.5M21 3v4.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' /></Icon>
)

export const CurveIcon: React.FC<IconProps> = ({ className = 'mt-n3 ms-2 text-secondary', style, width = 17 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 11.75 19.061'><path d='M10 14.25h.75v1.5H10zm-8.923-.888l.668-.34zm1.311 1.311l.34-.668zM1.5 0v10.2H0V0zm4.05 14.25H10v1.5H5.55zM1.5 10.2l.038 1.91a2.41 2.41 0 0 0 .207.912l-1.336.68c-.219-.461-.343-.961-.366-1.47C0 11.694 0 11.027 0 10.2zm4.05 5.55c-.828 0-1.494 0-2.032-.043-.509-.024-1.009-.148-1.47-.366L2.728 14a2.41 2.41 0 0 0 .913.207c.462.037 1.056.038 1.909.038v1.5zm-3.805-2.729a2.25 2.25 0 0 0 .984.984l-.681 1.336A3.75 3.75 0 0 1 .408 13.7l1.337-.681z' fill='currentColor' /><path d='M8 12l3 3-3 3' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' /></Icon>
)

export const DashboardIcon: React.FC<IconProps> = ({ className, style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 20 20' fill='none' stroke='currentColor' strokeWidth='1.5'><rect x='.75' y='.75' width='7.5' height='7.5' rx='1.25' /><rect x='11.75' y='.75' width='7.5' height='7.5' rx='1.25' /><rect x='11.75' y='11.75' width='7.5' height='7.5' rx='1.25' /><rect x='.75' y='11.75' width='7.5' height='7.5' rx='1.25' /></Icon>
)

export const TestTypesIcon: React.FC<IconProps> = ({ className, style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 19 21'><path fill='currentColor' d='M5.962 3.131a.264.264 0 1 0 0 .528H9.88a.264.264 0 1 0 0-.528ZM9.88 1.723a1.69 1.69 0 0 1 1.626 1.217h2.3a1.769 1.769 0 0 1 1.511.669 2.255 2.255 0 0 1 .361 1.462v13.658a2.353 2.353 0 0 1-.347 1.515 1.755 1.755 0 0 1-1.53.756H2.264a2.37 2.37 0 0 1-1.767-.638 2.265 2.265 0 0 1-.491-1.584V5.081a2.071 2.071 0 0 1 .547-1.553 2.353 2.353 0 0 1 1.737-.587h2.043a1.69 1.69 0 0 1 1.629-1.218Zm0 3.345H5.962a1.7 1.7 0 0 1-1.39-.718H2.296a1.128 1.128 0 0 0-.72.159.745.745 0 0 0-.145.558v13.722a1.014 1.014 0 0 0 .138.641 1.322 1.322 0 0 0 .723.158h11.5c.211 0 .3-.042.345-.114a1.076 1.076 0 0 0 .116-.7V5.033a1.014 1.014 0 0 0-.1-.607c-.027-.038-.122-.076-.354-.078h-2.533a1.7 1.7 0 0 1-1.387.722ZM17.101 0a1.894 1.894 0 0 1 1.9 1.878v14.781a1.894 1.894 0 0 1-1.9 1.878h-2.067v-1.408H17.1a.473.473 0 0 0 .475-.469V1.878a.473.473 0 0 0-.475-.469H9.127a.473.473 0 0 0-.475.469v.164H7.227v-.164A1.894 1.894 0 0 1 9.127 0ZM7.606 12.33a.704.704 0 1 1 0 1.408H3.324a.704.704 0 1 1 0-1.408Zm4.276-2.817a.704.704 0 1 1 0 1.408H3.324a.704.704 0 1 1 0-1.408Zm0-2.817a.704.704 0 1 1 0 1.408H3.324a.704.704 0 1 1 0-1.408Z' /></Icon>
)

export const ClientsIcon: React.FC<IconProps> = ({ className, style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 21 22' stroke='currentColor' fill='none' strokeWidth='1.5'><path d='m15.93 6.789.463-.463a1.966 1.966 0 1 1 2.781 2.781l-.463.464M15.93 6.789a3.277 3.277 0 0 0 2.781 2.781M15.93 6.789l-4.261 4.261a6.837 6.837 0 0 0-.557.592 3.284 3.284 0 0 0-.374.606 6.826 6.826 0 0 0-.28.763l-.408 1.239-.134.4m8.8-5.081-4.266 4.262a6.837 6.837 0 0 1-.592.557 3.284 3.284 0 0 1-.606.374 6.826 6.826 0 0 1-.763.28l-1.24.413-.4.134m0 0-.4.134a.53.53 0 0 1-.671-.671l.134-.4m.938.938-.938-.938' /><path d='M5.75 11.75h2.5m-2.5-4h6.5m-6.5 8h1.5M17.578 1.922C16.407.75 14.521.75 10.75.75h-2c-3.771 0-5.657 0-6.828 1.172S.75 4.979.75 8.75v4c0 3.771 0 5.657 1.172 6.828S4.979 20.75 8.75 20.75h2c3.771 0 5.657 0 6.828-1.172.943-.943 1.127-2.349 1.163-4.828' strokeLinecap='round' /></Icon>
)

export const TestsIcon: React.FC<IconProps> = ({ className, style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 24 24'><path fill='currentColor' d='M11.553 1.106a1 1 0 0 1 .894 0l10 5a1 1 0 0 1 0 1.788l-10 5a1 1 0 0 1-.894 0l-10-5a1 1 0 0 1 0-1.788l10-5ZM4.236 7 12 10.882 19.764 7 12 3.118 4.236 7Z' fillRule='evenodd' clipRule='evenodd' /><path fill='currentColor' d='M1.106 16.553a1 1 0 0 1 1.341-.448L12 20.883l9.553-4.777a1 1 0 0 1 .894 1.79l-10 5a1 1 0 0 1-.894 0l-10-5a1 1 0 0 1-.447-1.342Z' /><path fill='currentColor' d='M2.447 11.105a1 1 0 0 0-.894 1.79l10 5a1 1 0 0 0 .894 0l10-5a1 1 0 1 0-.894-1.79L12 15.883l-9.553-4.777Z' /></Icon>
)

export const FaqsIcon: React.FC<IconProps> = ({ className, style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 20 20'><path fill='currentColor' d='M10 0A10 10 0 1 1 0 10 10 10 0 0 1 10 0Zm0 1.4a8.6 8.6 0 1 0 8.6 8.6A8.6 8.6 0 0 0 10 1.4Zm0 12.241a.909.909 0 1 1-.909.909.909.909 0 0 1 .909-.914Zm2.68-8.311a2.764 2.764 0 0 1 .838 2.435 3.306 3.306 0 0 1-1.085 1.926c-.7.607-.871.756-1 .889l-.06.062-.1.11a2.6 2.6 0 0 0-.537.915 4.306 4.306 0 0 0-.183.906.682.682 0 0 1-1.354-.159 5.533 5.533 0 0 1 .255-1.209 3.94 3.94 0 0 1 .82-1.379c.17-.184.366-.371.615-.593l.679-.59a1.955 1.955 0 0 0 .606-1.065 1.414 1.414 0 0 0-.455-1.286 2.068 2.068 0 0 0-1.626-.572 2.412 2.412 0 0 0-1.593.668 2.484 2.484 0 0 0-.75 1.519.682.682 0 1 1-1.344-.227A3.821 3.821 0 0 1 7.6 5.364a3.7 3.7 0 0 1 2.312-1 3.424 3.424 0 0 1 2.768.966Z' /></Icon>
)

export const MessagingIcon: React.FC<IconProps> = ({ className, style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 20 20'><path fill='currentColor' d='M10 1.395a8.608 8.608 0 0 0-7.707 12.436 2.217 2.217 0 0 1 .174 1.559l-.554 2.071a.512.512 0 0 0 .626.627l2.071-.554a2.217 2.217 0 0 1 1.559.174A8.606 8.606 0 1 0 10 1.395Zm-10 8.6a9.994 9.994 0 1 1 5.55 8.961.824.824 0 0 0-.577-.075l-2.071.554A1.907 1.907 0 0 1 .565 17.1l.554-2.071a.824.824 0 0 0-.075-.577A9.962 9.962 0 0 1 0 10Zm5.581-1.4a.7.7 0 0 1 .7-.7h7.442a.7.7 0 1 1 0 1.4H6.279a.7.7 0 0 1-.698-.69Zm0 3.256a.7.7 0 0 1 .7-.7h5.116a.7.7 0 1 1 0 1.4H6.279a.7.7 0 0 1-.698-.691Z' fillRule='evenodd' /></Icon>
)

export const SaveIcon: React.FC<IconProps> = ({ className, style, width = 20 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 20 20'><path d='M10 20A9.989 9.989 0 0 1 2.937 2.937a9.989 9.989 0 1 1 14.126 14.126A9.929 9.929 0 0 1 10 20zm0-18.6a8.593 8.593 0 0 0-6.076 14.676A8.593 8.593 0 1 0 16.076 3.924 8.543 8.543 0 0 0 10 1.395zM8.722 13.58a.64.64 0 0 1-.477-.22L5.7 10.53a.81.81 0 0 1 0-1.061.628.628 0 0 1 .955 0l2.07 2.3 4.626-5.131a.628.628 0 0 1 .955 0 .81.81 0 0 1 0 1.061l-5.1 5.66a.64.64 0 0 1-.484.221z' /></Icon>
)

export const BulletIcon: React.FC<IconProps> = ({ className, style, width = 10 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 10 6'><rect width='10' height='6' fill='none' /><circle cx='2' cy='2' r='2' fill='currentColor' /></Icon>
)

export const AddCircleIcon: React.FC<IconProps> = ({ className = 'text-primary', style, width = 10 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 273 273'><g fill='currentColor' fillOpacity='.1' stroke='currentColor'><circle cx='136.5' cy='136.5' r='136.5' stroke='none' /><circle cx='136.5' cy='136.5' r='131' fill='none' strokeWidth='11' strokeOpacity='.2' /></g><rect width='109.114' height='17.926' rx='8.963' transform='rotate(90 31.76 113.703)' fill='currentColor' /><rect fill='currentColor' width='109.114' height='17.926' rx='8.963' x='81.943' y='127.537' /></Icon>
)

export const PlusCircleIcon: React.FC<IconProps> = ({ className = 'd-block my-auto', style, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 22 22'><g fill='currentColor'><path d='M11 2.78A8.22 8.22 0 1 0 19.22 11 8.22 8.22 0 0 0 11 2.78M11 1A10 10 0 1 1 1 11 10 10 0 0 1 11 1Z' /><rect width='11' height='1.78' rx='.89' transform='translate(5.5 10.11)' /><rect width='11' height='1.78' rx='.89' transform='rotate(90 3.195 8.695)' /></g></Icon>
)

export const PlusIcon: React.FC<IconProps> = ({ className = 'h-auto', style = { margin: '-3px 8px 0 0' }, width = 11 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 11 11' fill='currentColor'><rect width='1.9' height='11' rx='.95' x='4.55' /><rect width='1.9' height='11' rx='.95' transform='rotate(90 3.225 7.775)' /></Icon>
)

// export const PinIcon: React.FC<IconProps> = ({ className = 'text-gray', style, width = 24 }) => (
//   <Icon className={className} width={width} style={style} viewBox='0 0 24 24'><path fill='currentColor' fillRule='evenodd' d='m22.778 8.736-12.21 12.058a5.23 5.23 0 0 1-7.328 0 5.07 5.07 0 0 1 0-7.235L14.23 2.701a3.485 3.485 0 0 1 4.885 0 3.38 3.38 0 0 1 0 4.823L8.125 18.382a1.74 1.74 0 0 1-2.443 0 1.69 1.69 0 0 1 0-2.412l9.77-9.647-1.22-1.21-9.77 9.649a3.38 3.38 0 0 0 0 4.824 3.485 3.485 0 0 0 4.885 0l10.99-10.853a5.07 5.07 0 0 0 0-7.235 5.23 5.23 0 0 0-7.328 0L1.408 12.96l.043.041a6.76 6.76 0 0 0 .567 9 6.97 6.97 0 0 0 9.117.561l.042.042L23.999 9.947l-1.224-1.206' /></Icon>
// )
export const PinIcon: React.FC<IconProps> = ({
  className = 'text-gray',
  style,
  width = 24,
  onClick  
}) => (
  <Icon className={className} width={width} style={style} viewBox="0 0 24 24" onClick={onClick}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m22.778 8.736-12.21 12.058a5.23 5.23 0 0 1-7.328 0 5.07 5.07 0 0 1 0-7.235L14.23 2.701a3.485 3.485 0 0 1 4.885 0 3.38 3.38 0 0 1 0 4.823L8.125 18.382a1.74 1.74 0 0 1-2.443 0 1.69 1.69 0 0 1 0-2.412l9.77-9.647-1.22-1.21-9.77 9.649a3.38 3.38 0 0 0 0 4.824 3.485 3.485 0 0 0 4.885 0l10.99-10.853a5.07 5.07 0 0 0 0-7.235 5.23 5.23 0 0 0-7.328 0L1.408 12.96l.043.041a6.76 6.76 0 0 0 .567 9 6.97 6.97 0 0 0 9.117.561l.042.042L23.999 9.947l-1.224-1.206"
    />
  </Icon>
);

export const PlusIcon2: React.FC<IconProps> = ({ className = 'h-auto', style = { margin: '-3px 8px 0 0' }, width = 11 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 15 15'><path d='M6.25 15.004V8.75H-.004v-2.5h6.25V.004h2.5v6.25h6.25v2.5H8.75v6.25Z' fill='currentColor' opacity='.7' /></Icon>
)

export const PencilFilledIcon: React.FC<IconProps> = ({ className, style = { marginTop: -3, marginRight: 6 }, width = 10 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 11 13'><path d='M10.744 1.481a.681.681 0 0 1 .1.963l-7.279 8.944L0 13l.841-3.8L8.123.254a.691.691 0 0 1 .969-.1Z' fill='currentColor' /></Icon>
)

export const DownIcon: React.FC<IconProps> = ({ className, style = { marginTop: -4.5, marginLeft: 6 }, width = 11 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 12.5 14.525' fill='currentColor'><rect width='2' height='8.5' rx='1' transform='rotate(135 1.116 8.142)' /><rect width='2' height='14' rx='1' x='5.238' y='.1' /><rect width='2' height='8.5' rx='1' transform='rotate(225 6.134 5.968)' /></Icon>
)

export const RightIcon: React.FC<IconProps> = ({ className = 'ms-2', style = { marginTop: -3 }, width = 6.5 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 6 10.5'><path d='m1.061 1.061 4.189 4.19L1.061 9.44' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' /></Icon>
)

export const DownloadIcon: React.FC<IconProps> = ({ className, style = { marginTop: -3, marginRight: 6 }, width = 16 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 19 17.865'><path d='M1 10.865c0 2.828 0 4.243.83 5.121s2.17.879 4.837.879h5.667c2.671 0 4.007 0 4.837-.879S18 13.693 18 10.865M9.307 1v11m0 0 4-4.429m-4 4.429-4-4.429' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' /></Icon>
)

export const DeleteIcon: React.FC<IconProps> = ({ className, style = { marginTop: '-3' }, width = 16 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 22 25'><path d='M7.667 22.992a4.43 4.43 0 0 1-4.444-4.4V7.6H2.111a1.1 1.1 0 1 1 0-2.2h4.445V4.3A3.317 3.317 0 0 1 9.889 1h2.222a3.325 3.325 0 0 1 3.333 3.3v1.107h4.444a1.1 1.1 0 1 1 0 2.2h-1.11V18.6a4.436 4.436 0 0 1-4.444 4.4Zm-2.232-4.4a2.189 2.189 0 0 0 .654 1.559 2.234 2.234 0 0 0 1.578.641h6.654a2.211 2.211 0 0 0 2.222-2.2V7.6H5.432ZM13.212 5.4V4.3A1.106 1.106 0 0 0 12.1 3.2H9.879a1.106 1.106 0 0 0-1.111 1.1v1.1Z' fill='currentColor' /></Icon>
)

export const PencilIcon: React.FC<IconProps> = ({ className, style = { marginTop: '-3' }, width = 18, onClick }) => (
  <Icon className={onClick && 'stretched-link cursor-pointer ' + className} width={width} style={style} onClick={onClick} viewBox='0 0 22 22' fill='currentColor'><path d='M1.871 21a.854.854 0 0 1-.61-.246.88.88 0 0 1-.258-.7l.363-4.507a.857.857 0 0 1 .258-.552L14.59 2.012A3.737 3.737 0 0 1 17.262 1 3.866 3.866 0 0 1 19.9 2.07a3.888 3.888 0 0 1 .134 5.281L7.052 20.39a.9.9 0 0 1-.539.258Zm1.172-5L2.8 19.216l3.212-.294L18.786 6.107a2.018 2.018 0 0 0-1.559-3.321 1.886 1.886 0 0 0-1.395.493Z' /></Icon>
)

export const EmptyCircleIcon: React.FC<IconProps> = ({ className = 'text-metal hide-when-open me-3', style = { marginTop: -.5 }, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 30 30'><defs><style>{`.hide-when-open{display:none}.collapsed .hide-when-open{display:inline-block}`}</style></defs><circle cx='15' cy='15' r='15' fill='none' /><g transform='translate(1 1)' fill='none' stroke='currentColor' strokeOpacity='.5' strokeWidth='3'><circle cx='14' cy='14' r='14' stroke='none' /><circle cx='14' cy='14' r='13' fill='none' /></g></Icon>
)

export const FilledCircleIcon: React.FC<IconProps> = ({ className = 'text-secondary show-when-open me-3', style = { marginTop: -.5 }, width = 18 }) => (
  <Icon className={className} width={width} style={style} viewBox='0 0 30 30'><defs><style>{`.show-when-open{display:inline-block}.collapsed .show-when-open{display:none}`}</style></defs><circle cx='15' cy='15' r='15' fill='none' /><g transform='translate(1 1)' fill='none' stroke='currentColor' strokeWidth='3'><circle cx='14' cy='14' r='14' stroke='none' /><circle cx='14' cy='14' r='13' fill='none' /></g><circle fill='currentColor' cx='6.25' cy='6.25' r='6.25' transform='translate(8.75 8.75)' /></Icon>
)

export const TestCountIcon: React.FC<IconProps> = ({ className, style, width = 24, number }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 22 22'><path fill='none' d='M0 0h22v22H0z' /><g transform='translate(1 1)' fill='currentColor' stroke='rgba(0,0,0,0)' strokeWidth='2'><circle cx='10' cy='10' r='10' stroke='none' /><circle cx='10' cy='10' r='9' fill='none' /></g><text x='50%' y='57%' dominantBaseline='middle' textAnchor='middle' fill='#fff' fontSize='13' fontFamily='Satoshi, sans-serif' fontWeight='500'><CountUpNumber number={number} /></text></Icon>
)

export const ArrowIcon: React.FC<IconProps> = ({ className = 'my-auto', style = { marginLeft: -7, zIndex: 9999999 }, width = 9 }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 9.428 16'><path fill='#fff' fillOpacity='.2' d='M.324 15.615a1.11 1.11 0 0 1 .014-1.582l.012-.012q3.012-2.938 6.254-6.111L.616 1.916l-.018-.031A1.183 1.183 0 0 1 1.506 0a1.3 1.3 0 0 1 .746.266l.031.023 6.8 6.816a1.124 1.124 0 0 1 .34.811 1.178 1.178 0 0 1-.328.809l-.018.014c-2.441 2.385-4.826 4.715-7.088 6.928l-.008.006a1.294 1.294 0 0 1-.834.328 1.075 1.075 0 0 1-.823-.386Z' /></Icon>
)

export const ArrowRightIcon: React.FC<IconProps> = ({ className, width = 14, style = { marginBottom: 3 } }) => (
  <Icon className={className + ' transition'} style={style} width={width} viewBox='0 0 15 13'><path fill='currentColor' d='M8.326 12.74a1 1 0 0 1-.068-1.413l3.473-3.828H.999a1 1 0 1 1 0-2h10.732L8.258 1.671A1.006 1.006 0 0 1 8 .95 1 1 0 0 1 9.736.326l4.895 5.4a1 1 0 0 1 0 1.552l-4.9 5.4a1 1 0 0 1-.691.326h-.049a1 1 0 0 1-.665-.264z' /></Icon>
)
export const InfoIcon: React.FC<IconProps> = ({ className = 'text-dark', style, width = 5 }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 6 15' ><path fill='currentColor' d='M4.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 1 1 3 0zM0 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1 1 1 0 0 1 1-1h1V7H1a1 1 0 0 1-1-1z' /></Icon>
)

export const LongArrowIcon: React.FC<IconProps> = ({ className, style = { marginTop: -3 }, width = 36 }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 46.414 12.828'><path d='M1 6.414h41' fill='none' stroke='currentColor' strokeLinecap='round' strokeWidth='2' /><path d='M45 6.414H33m12 0-5 5m5-5-5-5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' /></Icon>
)

export const PelletIcon: React.FC<IconProps> = ({ className, style = { marginTop: -3 }, width = 21 }) => (
  <Icon className={className = className + ' me-2'} style={style} width={width} viewBox='0 0 21 11'><rect width='21' height='11' rx='5.5' fill='currentColor' /></Icon>
)

export const ShieldIcon: React.FC<IconProps> = ({ className, style = { marginTop: -1 }, width = 32 }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 41 41'><circle cx='20.5' cy='20.5' r='20.5' fill='none' /><g transform='translate(1 1)' fill='currentColor' fill-opacity='.09' stroke='currentColor' strokeWidth='2'><circle cx='19.5' cy='19.5' r='19.5' stroke='none' /><circle cx='19.5' cy='19.5' r='18.5' fill='none' /></g><path d='M14,0a1,1,0,0,1,1,1V9c0,2.5-1.816,4.246-3.445,5.332a15.077,15.077,0,0,1-3.238,1.617.976.976,0,0,1-.633,0,15.077,15.077,0,0,1-3.238-1.617C2.816,13.246,1,11.5,1,9V1A1,1,0,0,1,2,0ZM13,2H3V9c0,1.5,1.184,2.754,2.555,3.668A10.717,10.717,0,0,0,8,13.895a10.717,10.717,0,0,0,2.445-1.227C11.816,11.754,13,10.5,13,9ZM12,3V9c0,1-.867,2.008-2.109,2.836A7.992,7.992,0,0,1,8,12.785V3Zm0,0' transform='translate(12.5 13.5)' fill='currentColor' />;</Icon>
)

export const SignoutIcon: React.FC<IconProps> = ({ className = 'mt-n1 me-2', style, width = 15 }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 15.5 15.498' ><g fill='currentColor'><path d='M5.177 15.246H2.606A2.262 2.262 0 0 1 .25 13.079V2.419A2.262 2.262 0 0 1 2.606.252h2.571a.643.643 0 0 1 0 1.285H2.606a.994.994 0 0 0-1.071.857v10.685a.994.994 0 0 0 1.071.857h2.571a.643.643 0 1 1 0 1.285Z' /><path d='M11.18 11.819a.634.634 0 0 1-.454-.189.643.643 0 0 1 0-.908l2.973-2.973-2.973-2.973a.643.643 0 1 1 .908-.908l3.427 3.427a.643.643 0 0 1 0 .908l-3.427 3.429a.634.634 0 0 1-.454.189Z' /><path d='M14.607 8.391H5.182a.643.643 0 0 1 0-1.285h9.425a.643.643 0 0 1 0 1.285Z' /></g></Icon>
)

export const CountNumberIcon: React.FC<IconProps> = ({ className, style, width = 25, number, isSwitch, isGradient, fill = isSwitch ? 'white' : 'metal', text = isSwitch ? 'gray-dark' : 'white' }) => (
  <Icon className={className + ' mt-n1 me-2'} style={style} width={width} viewBox='0 0 28 28'><defs><linearGradient id='lg' x1='30%' y1='70%'><stop offset='0%' stopColor='#eb2e25'><animate attributeName='stopColor' values='#eb2e25; #4826a4; #eb2e25' dur='3s' repeatCount='indefinite'></animate></stop><stop offset='100%' stopColor='#4826a4'><animate attributeName='stopColor' values='#4826a4; #eb2e25; #4826a4;' dur='3s' repeatCount='indefinite'></animate></stop></linearGradient></defs><circle className={'text-' + fill} cx='14' cy='14' r='14' fill={isGradient ? '#fff' : 'currentColor'} /><text className={'text-' + text} fill={isGradient ? 'url(#lg)' : 'currentColor'} x='50%' y='57%' dominantBaseline='middle' textAnchor='middle' fontSize='17' fontFamily='Satoshi, sans-serif' fontWeight='800'>{number}</text></Icon>
)

export const CountTickIcon: React.FC<IconProps> = ({ className, style, width = 25, number, fill = 'success', text = 'white' }) => (
  <Icon className={className + ' mt-n1 me-2'} style={style} width={width} viewBox='0 0 28 28'><circle className={'text-' + fill} cx='14' cy='14' r='14' fill='currentColor' /><path className={'text-' + text} d='m13.268 19.524-5.235-4.027 1.489-1.887 3.214 2.473 5.306-7.56 1.991 1.362Z' fill='currentColor' /></Icon>
)

export const NumberIcon: React.FC<IconProps> = ({ className, style, width = 30, number }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 30 30'><circle cx='15' cy='15' r='15' fill='currentColor' fillOpacity='.06' /><text x='50%' y='55%' fill='currentColor' dominantBaseline='middle' textAnchor='middle' fontSize='14' fontFamily='Satoshi, sans-serif' fontWeight='500'><CountUpNumber number={number} /></text></Icon>
)

export const ChatIcon: React.FC<IconProps> = ({ className = 'me-2', style = { marginTop: -1 }, width = 15 }) => (
  <Icon className={className} style={style} width={width} fill='currentColor' viewBox='0 0 16 16'><path d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2' /></Icon>
)

export const LiveIcon: React.FC<IconProps> = ({ className, width = 28, style }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 24 24' fill='currentColor'><circle cx='12' cy='12' r='12'><animate attributeName='r' values='0;12' dur='2s' repeatCount='indefinite' /><animate attributeName='fill-opacity' values='.8;0' dur='2s' repeatCount='indefinite' /></circle><circle cx='5' cy='5' r='3' transform='translate(7 7)' /></Icon>
)

export const CheckIcon: React.FC<IconProps> = ({ className, width = 36, style }) => (
  <Icon className={'me-3 ' + className} style={{ ...style, marginTop: -2 }} width={width} viewBox='0 0 204 125'><rect x='3' y='3' width='198' height='119' rx='59.5' fill='currentColor' fillOpacity='.4' strokeOpacity='0' strokeWidth='6' /><circle cx='141' cy='63' r='44' fill='#fff' /></Icon>
)

export const SendIcon: React.FC<IconProps> = ({ className = 'mb-3 mt-4 text-primary ms-n2', width = 30, style }) => (
  <Icon className={className} style={{ ...style, marginTop: -2 }} width={width} fill='currentColor' viewBox='0 0 16 16'><path d='M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z' /></Icon>
)

export const SuccessIcon: React.FC<IconProps> = ({ className, width = 120, style }) => (
  <Icon className={className ? className : 'text-success'} style={style} width={width} viewBox='0 0 107 107'><circle cx='53.5' cy='53.5' r='53.5' fill='currentColor'><animate attributeName='r' values='20.5;53.5' dur='3s' repeatCount='indefinite' /><animate attributeName='fill-opacity' values='.5;0' dur='3s' repeatCount='indefinite' /></circle><circle cx='53.5' cy='53.5' r='20.5' fill='currentColor' /><path d='M51.201 60.74a1.5 1.5 0 0 1-1.065-.441l-4.975-4.974a1.504 1.504 0 1 1 2.127-2.127l3.913 3.912 10.043-10.044a1.504 1.504 0 1 1 2.127 2.127L52.265 60.3a1.5 1.5 0 0 1-1.063.44Z' fill='#fff' /></Icon>
)

export const FailureIcon: React.FC<IconProps> = ({ className = 'text-danger', width = 120, style }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 107 107'><circle cx='53.5' cy='53.5' r='53.5' fill='currentColor'><animate attributeName='r' values='20.5;53.5' dur='3s' repeatCount='indefinite' /><animate attributeName='fill-opacity' values='.5;0' dur='3s' repeatCount='indefinite' /></circle><circle cx='53.5' cy='53.5' r='20.5' fill='currentColor' /><rect width='3' height='19' rx='1.5' transform='translate(59.157 45.722) rotate(45)' fill='#fff' /><rect width='3' height='19' rx='1.5' transform='translate(61.278 59.157) rotate(135)' fill='#fff' /></Icon>
)

export const LoaderIcon: React.FC<IconProps> = ({ className = 'mx-auto d-block', width = 50, style }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 100 100'><path className='text-danger' fill='currentColor' d='M42.3 39.6c5.7-4.3 13.9-3.1 18.1 2.7 4.3 5.7 3.1 13.9-2.7 18.1l4.1 5.5c8.8-6.5 10.6-19 4.1-27.7-6.5-8.8-19-10.6-27.7-4.1l4.1 5.5z'><animateTransform attributeName='transform' attributeType='XML' type='rotate' dur='.7s' from='0 50 50' to='-360 50 50' repeatCount='indefinite' /></path><path className='text-secondary' fill='currentColor' d='M82 35.7C74.1 18 53.4 10.1 35.7 18S10.1 46.6 18 64.3l7.6-3.4c-6-13.5 0-29.3 13.5-35.3s29.3 0 35.3 13.5l7.6-3.4z'><animateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1.2s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /></path></Icon>
)

export const DownExpandIcon: React.FC<IconProps> = ({ className, width = 16, style }) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 16 9' fill='currentColor'><path d="M0 1.268L1.305 0 8 6.5 14.695 0 16 1.268 8 9z"/></Icon>
)

export const StarIcon: React.FC<IconProps> = ({ className = 'text-success', width = 16, style, isSwitch, onMouseEnter, onMouseLeave }) => (
  <Icon className={className} style={style} width={width} fill="currentColor" viewBox="0 0 16 16" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    {!isSwitch && <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />}
    {isSwitch && <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />}
  </Icon>
)

export const NotifsIcon: React.FC<IconProps> = ({ className = 'h-auto my-3', style, width = 45 ,unread}) => (
  <Icon className={className} style={style} width={width} viewBox='0 0 66 66'>
    <style>{'.notifs.show circle, .notifs:hover circle {opacity: 1}'}</style>
    <circle className='text-white' cx='33' cy='33' r='33' fill='currentColor' opacity='0' />
    <path fill='#fff' d='M29.775 40.528a3.279 3.279 0 0 1 3.5 3 3.279 3.279 0 0 1-3.5 3 3.279 3.279 0 0 1-3.5-3 3.279 3.279 0 0 1 3.5-3Z' />
    <path fill='#fff' d='M29.775 25.528c4.142 0 7.758 5.391 7.758 10.361 0 2.1 2.558 5.785 1.295 7.265-1.243 1.474-6.663.374-9.053.374-4.142 0-9.637 4.411-9.637-.56 0-2.684.862-9.822 3.135-14 1.665-3.069 4.597-3.44 6.502-3.44Z' />
    <path fill='#001e00' d='M38.704 45.075c-.07 0-.139 0-.209-.011H20.516a1.979 1.979 0 0 1-1.787-1.126 1.911 1.911 0 0 1 .164-1.989l1.8-2.711v-7a8.539 8.539 0 0 1 2.459-6.036 8.218 8.218 0 0 1 5.885-2.476h1a8.191 8.191 0 0 1 5.887 2.522 8.539 8.539 0 0 1 2.452 5.995v6.889l1.833 2.979a1.983 1.983 0 0 1 .111 2.015 1.808 1.808 0 0 1-1.616.949Zm-18-2.088H38.31l-1.707-2.775a1.711 1.711 0 0 1-.3-1.058v-6.911a6.473 6.473 0 0 0-1.862-4.543 6.13 6.13 0 0 0-4.4-1.9h-1a6.361 6.361 0 0 0-6.265 6.443v7.024a1.564 1.564 0 0 1-.323 1.086Z' />
    <path fill='#001e00' d='M29.48 48.463a4.292 4.292 0 0 1-4.249-4.324 1.039 1.039 0 1 1 2.077 0 2.173 2.173 0 1 0 4.343 0 1.039 1.039 0 1 1 2.077 0 4.292 4.292 0 0 1-4.248 4.324Z' />
     {/* uncomment these if there is any notification unread */}
    {unread > 0?
   ( <><path fill='#f4f5f7' d='M39.475 17.538a8 8 0 1 1-8 8 8 8 0 0 1 8-8Z' /><path fill='#EB2E25' d='M39.475 19.537a6 6 0 1 1-6 6 6 6 0 0 1 6-6Z' /></> ):null}
  </Icon>
)