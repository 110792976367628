import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { PencilIcon, DeleteIcon } from '../../Global/Icons';
import { AnimatedProgress, FadeModal, OverlayLink, LinkBadge } from '../../Global/Transitions';
import Tests from '../../../Models/Tests';
import Project from '../../../Models/Project';
import EditProject from '../../Popups/EditProject';
import TestProps from '../../../Utilities/TestProps';
import { getProjectByTestId } from '../../../Services/PtaasApiService';
import { useMsal } from '@azure/msal-react';



interface ProjectsRowProps {
  test: Tests;
  projects?: Project[] ;
}

const ProjectsRow: React.FC<ProjectsRowProps> = ({ test, projects }) => {
  const { instance, accounts } = useMsal();
  const props = TestProps[test.status];
  const [show, setShow] = useState(false);
  const handleHide = () => setShow(false);
  const [action, setAction] = useState(null);
  const isCreated = test.status === 'Created';
  const [complete, setComplete] = useState(false);
  const handleComplete = () => setComplete(true);
  const [projectId, setProjectId] = useState<number | null>(null);
  
 
  const link = '/projects/' + test.id + '/' + (isCreated ? 'setup' : (projectId || '' ));
  const handleShow = (action) => { setComplete(false); setAction(action); setShow(true) };
  
  
  useEffect(() => {
    const fetchProject = async () => {
      let returnedproject:Project;
        
        try {
          returnedproject=await getProjectByTestId(instance,accounts[0],test.id);
          setProjectId(returnedproject?.id || null);
        } catch (error) {
          console.error('Error fetching project data:', error);
          setProjectId(null);
        } finally {
          
        }
      
    };
    fetchProject();
  }, [test.projectName, projects,accounts,instance,test.id]);
  return (
    <tr key={test.id}>
      <td><Link to={link} className='stretched-link'>{test.projectName}</Link></td>
      <td><LinkBadge to={link} bullet bg={props.bg} text={props.text} border={props.border}>{test.status}</LinkBadge></td>
      <td>{test.projectOwner}</td>
      <td>{formatDistanceToNow(+test._ts * 1000)} ago</td>
      <td width={70}><AnimatedProgress initialProgress={props.progress} /></td>
      <td width={40}><OverlayLink body={<PencilIcon />} disabled={!isCreated} onClick={() => handleShow('edit')} placement='left' /></td>
      <td width={60}><OverlayLink body={<DeleteIcon />} disabled={!isCreated} onClick={() => handleShow('delete')} className='text-danger' /></td>
      <FadeModal show={show} onHide={handleHide} title={complete ? '' : (action === 'edit' ? 'Edit' : 'Delete') + ' Project'}>
        <EditProject action={action} test={test} close={handleHide} complete={handleComplete} />
      </FadeModal>
    </tr>
  );
};


export default ProjectsRow;