import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { FadeIn, FloatingField } from '../Global/Transitions';
import { DownExpandIcon, SuccessIcon } from '../Global/Icons';
import { postnewproject, putprojectdetails, getTestType, Savefeedback } from '../../Services/PtaasApiService';
import { Form, Button, FormGroup, Row, Col, ModalBody, Collapse, FloatingLabel } from 'react-bootstrap';
import Tests from '../../Models/Tests';
import Feedback from '../Global/Feedback';
import TestType from '../../Models/TestType';




interface Option {
  key: string[];
  selectAll: boolean;
  selections: string[];
}

interface EditProjectProps {
  test?: Tests;
  action: string;
  close: () => void;
  complete: () => void;
  
}

const Completion: React.FC<EditProjectProps> = ({ test,action, close }) => {
  const [giveFeedback, setGiveFeedback] = useState(false);
  const [rating, setRating] = useState(0);  // State to store rating
  const [comment, setComment] = useState(''); // Lifted state for feedback
  const { instance, accounts } = useMsal();

  const completionProps = {
    'edit': { title: 'Project Updated', text: 'Your project has been updated successfully.' },
    'delete': { title: 'Project Deleted', text: 'The selected project has been deleted successfully.' },
    'create': { title: 'Project Created', text: 'Congratulations! Your new project has been created successfully.' }
  };

  const props = completionProps[action];

  const handleFeedback = () => setGiveFeedback(true);

  // Save feedback when "Continue" is clicked
  const handleContinue = async () => {
    if (giveFeedback) {
      
      
      try {
        console.log("test id is",test?.id);
         await Savefeedback(instance,accounts[0],test?.id,comment,rating);  // Actual API call
      } catch (error) {
        console.error('Error saving feedback:', error);
      }
    }
    
    close();  // Close the modal or perform the completion action
  };

  return (
    <FadeIn>
      <div className="text-center py-5">
        <SuccessIcon/>
        <h1 className="m-0">{props.title}</h1>
        <p>{props.text}</p>
        {giveFeedback && (
          <Feedback
            rating={rating}
            comment={comment}
            setRating={setRating}
            setComment={setComment}
          />
        )}
        {!giveFeedback && <Button onClick={handleFeedback} variant='outline-primary' className='me-3'>Give Feedback</Button>}
        <Button onClick={handleContinue}>Continue</Button>  {/* Save feedback here */}
      </div>
    </FadeIn>
  );
};



const EditProject: React.FC<EditProjectProps> = ({ test, close, action, complete }) => {
  const { instance, accounts } = useMsal();
  const [show, setShow] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [deletionReason, setDeletionReason] = useState('');
  const [testTypes, setTestTypes] = useState<TestType[]>([]);
  const [ownerName, setOwnerName] = useState(test?.projectOwner || '');
  const [ownerEmail, setOwnerEmail] = useState(test?.owneremail || '');
  const [projectName, setProjectName] = useState(test?.projectName || '');
  const [isEdit, isCreate, isDelete] = ['edit', 'create', 'delete'].map(a => action === a);
  const [state, setState] = useState<Option>({ key: [], selections: [], selectAll: false });
  const [isProcessing, setIsProcessing] = useState(false);
  const[IsSubmitDisable,setIsSubmitDisable]=useState(false);
 

  useEffect(() => {
    if (completed) { complete() }
  }, [completed, complete]);

  useEffect(() => {
    async function fetchTestType(): Promise<void> {
      try {
        const returnedTestType = await getTestType(instance, accounts[0]);
       
        setTestTypes(returnedTestType);
        
        
      } catch (error) {  }
    }
    fetchTestType();
  }, [accounts, instance]);

  useEffect(() => {
    if (Array.isArray(test?.assignment)) {
      const assignid = test?.assignment.map((item) => item.assignmentId);
      setState((prevState) => ({ ...prevState, selections: test?.testType, key: assignid }));
      
    }
  }, [test?.assignment,test?.testType]);


  const handleChecked = (key: string, questionId: string): void => {
    const sel = [...state.selections];
    const find = sel.indexOf(key);
    const ky = [...state.key];
    const find2 = ky.indexOf(questionId);
    if (find > -1) sel.splice(find, 1);
    else sel.push(key);
    if (find2 > -1) ky.splice(find2, 1);
    else ky.push(questionId);
    setState((prevState) => ({ ...prevState, selections: sel, key: ky }));
  };

  const handleSelectAll = (): void => {
    const allTestTypeIds = testTypes.map((option) => option.id);
    if (state.selectAll) { setState((prevState) => ({ ...prevState, selections: [], key: [], selectAll: false })); }
    else { setState((prevState) => ({ ...prevState, selections: allTestTypeIds, key: allTestTypeIds, selectAll: true })); }
  };

 
  const handleCreate = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    if (isProcessing) return;
    setIsProcessing(true);
    setIsSubmitDisable(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      setIsProcessing(false);
      setIsSubmitDisable(false); // Enable the submit button again if validation fails
      return;
    } else {
      try {
        // Assuming that new assignments don't have Scoping and Prereq initially
        const newAssignments = state.key.map((key) => ({
          assignmentId: key,
          Scoping: [], // You can capture Scoping and Prereq from form fields if needed
          Prereq: []
        }));

        // Create the new project with assignments
        await postnewproject(instance, accounts[0], projectName, ownerName, ownerEmail, 'Created', state.selections.toString(), newAssignments);
        setValidated(true);
        setCompleted(true);
      } catch (error) {
        console.error('Failed to create project:', error);
      } finally {
        setIsProcessing(false);
        setIsSubmitDisable(false);
      }
    }
};

const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      try {
        // Construct the updated assignments
        const newAssignments = state.key.map((key) => {
          const existingAssignment = test?.assignment.find(a => a.assignmentId === key);

          return {
            assignmentId: key,
            // Preserve existing Scoping and Prereq values if they exist
            Scoping: existingAssignment?.scoping || [], // If the assignment exists, keep its Scoping; otherwise, initialize an empty array
            Prereq: existingAssignment?.prereq || []    // If the assignment exists, keep its Prereq; otherwise, initialize an empty array
          };
        });

        // Update the project details with the new assignments
        await putprojectdetails(
          instance, accounts[0], test.id, test.status, projectName, ownerName, ownerEmail,
          test.accepteddate, test.availabledate, test.comment, state.selections, newAssignments
        );
        
        setValidated(true);
        setCompleted(true);
      } catch (error) {
        console.error('Failed to update project:', error);
      }
    }
};

  const handleDelete = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      await putprojectdetails(instance, accounts[0], test.id, 'Closed', projectName, ownerName, ownerEmail, test.accepteddate, test.availabledate, deletionReason, test.testType,test.assignment)
      setValidated(true);
      setCompleted(true);
    }
  };

  return (
    <ModalBody>
      {completed ? <Completion test={test} action={action} close={close} complete={complete} /> :
        <FadeIn>
          <Form noValidate validated={validated} onSubmit={isCreate ? handleCreate : isEdit ? handleUpdate : handleDelete}>
            <FloatingField
              main
              disabled={isDelete}
              label='Project Name'
              defaultValue={isCreate ? '' : projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <Form.Group as={Row}>
              <Col md className='pe-md-2'>
                <FloatingField
                  disabled={!isCreate}
                  label='Project Owner Name'
                  defaultValue={isCreate ? '' : ownerName}
                  onChange={(e) => setOwnerName(e.target.value)}
                />
              </Col>
              <Col md className='ps-md-2'>
                <FloatingLabel label='Project Owner Email'>
                  <Form.Control
                    required
                    as='input'
                    type='email'
                    placeholder=''
                    spellCheck='false'
                    autoComplete='off'
                    disabled={!isCreate}
                    defaultValue={isCreate ? '' : ownerEmail}
                    onChange={(e) => setOwnerEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter a valid email address</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Form.Group>
            <Form.Group className={(isDelete ? '' : 'modal-list')}>
              <Form.Label>Test Types</Form.Label>
              {isCreate ? <Form.Check type='switch' className='pb-1' label='Select All' checked={state.selectAll} onChange={handleSelectAll} /> : null}
              {testTypes.filter(testType => state.selections.includes(testType.id)).map(selected => (
                <Form.Check
                  type='switch'
                  key={selected.id}
                  disabled={isDelete}
                  readOnly={isDelete}
                  className='mw-95'
                  label={
                    <div
                      onClick={() => setShow(show === selected.id ? null : selected.id)}
                      onMouseEnter={() => setHovered(selected.id)}
                      onMouseLeave={() => setHovered(null)}
                      className={
                        'transition px-2 mx-n2 py-1 my-n1 border border-2 rounded pe-link ' +
                        (hovered === selected.id || show === selected.id ? 'form-colors border-transparent' : 'border-white')
                      }
                    >
                      {selected.testTypeName}
                      <DownExpandIcon
                        width={11}
                        style={{ marginTop: -5 }}
                        className={
                          'mx-2 transition ' +
                          (show === selected.id ? 'text-primary-emphasis flip-180 ' : '') +
                          (hovered === selected.id ? 'text-primary-emphasis ' : 'text-white ')
                        }
                      />
                      <Collapse in={show === selected.id}>
                        <div>
                          <p className='mb-0 text-dark'>{selected?.description || 'No description available.'}</p>
                        </div>
                      </Collapse>
                    </div>
                  }
                  checked={state.selections.includes(selected.id)}
                  onChange={() => handleChecked(selected.id, selected.questionID)}
                />
              ))}
              {isDelete ? null : testTypes.filter(testType => !state.selections.includes(testType.id)).map(unselected => (
                <Form.Check
                  type='switch'
                  key={unselected.id}
                  disabled={isDelete}
                  readOnly={isDelete}
                  className='mw-95'
                  label={
                    <div
                      onClick={() => setShow(show === unselected.id ? null : unselected.id)}
                      onMouseEnter={() => setHovered(unselected.id)}
                      onMouseLeave={() => setHovered(null)}
                      className={
                        'transition px-2 mx-n2 py-1 my-n1 border border-2 rounded pe-link ' +
                        (hovered === unselected.id || show === unselected.id ? 'form-colors border-transparent' : 'border-white')
                      }
                    >
                      {unselected.testTypeName}
                      <DownExpandIcon
                        width={11}
                        style={{ marginTop: -5 }}
                        className={
                          'mx-2 transition ' +
                          (show === unselected.id ? 'text-primary-emphasis flip-180 ' : '') +
                          (hovered === unselected.id ? 'text-primary-emphasis ' : 'text-white ')
                        }
                      />
                      <Collapse in={show === unselected.id}>
                        <div>
                          <p className='mb-0 text-dark'>{unselected?.description || 'No description available.'}</p>
                        </div>
                      </Collapse>
                    </div>
                  }
                  checked={state.selections.includes(unselected.id)}
                  onChange={() => handleChecked(unselected.id, unselected.questionID)}
                />
              ))}
            </Form.Group>
            {isDelete &&
              <Form.Group className='mt-4 mb-1'>
                <Form.Label>Tell us why you want to delete the project</Form.Label>
                <FloatingField
                  main
                  negative
                  label='Reason for deletion'
                  feedback='Please provide a reason.'
                  onChange={(e) => setDeletionReason(e.target.value)} />
              </Form.Group>
            }
            <FormGroup className='mt-4'>
              <Button className='me-3' onClick={close} variant={isDelete ? 'outline-danger' : 'outline-primary'} >Cancel</Button>
              <Button type='submit' disabled={IsSubmitDisable} variant={isDelete ? 'danger' : 'primary'}>{isDelete ? 'Delete' : isEdit ? 'Update' : 'Submit'}</Button>
            </FormGroup>
          </Form>
        </FadeIn>
      }
    </ModalBody>
  );
};

export default EditProject;

