import React, { useEffect, useState } from 'react';
import { BulletIcon, MessagingIcon, PlusIcon, TestsIcon } from './Icons';
import { Link, NavLink } from 'react-router-dom';
import { Color, Placement } from 'react-bootstrap/esm/types';
import { Col, Row, Alert, Form, Fade, Modal, Badge, Button, Table, Accordion, Popover, ProgressBar, ToggleButton, FloatingLabel, OverlayTrigger, ToggleButtonGroup } from 'react-bootstrap';



interface FadeComponentProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const FadeIn: React.FC<FadeComponentProps> = ({ children }) => {
  const useFadeIn = () => {
    const [fadeIn, setIn] = useState(false);
    useEffect(() => {setIn(true)}, []);
    return fadeIn;
  };
  const enter = useFadeIn();
  return <Fade in={enter}>{children}</Fade>;
};


interface CrumbProps {
  to: string;
  base?: boolean;
  children: React.ReactNode;
}

export const Crumb: React.FC<CrumbProps> = ({ to, children }) => (
  <NavLink to={to} className={({ isActive }) => '' + (isActive ? '' : 'd-none')}>
    <span className='mx-2'>/</span>
    {children}
  </NavLink>
);



interface ActiveToggleProps {
  toggle: number;
  toggle1?: string;
  toggle2?: string;
  onChange: (value: any) => void;
}

export const ActiveToggle: React.FC<ActiveToggleProps> = ({ onChange, toggle, toggle1 = 'Active', toggle2 = 'Archived' }) => (
  <ToggleButtonGroup name='options' defaultValue={1} value={toggle} onChange={onChange} className='bg-light p-1 float-end'>
    <ToggleButton id='tbg-1' value={1} variant='light' style={{ minWidth: 100 }} className={'border-0 rounded-pill py-1 ' + (toggle < 2 && 'bg-white shadow')}>{toggle1}</ToggleButton>
    <ToggleButton id='tbg-2' value={2} variant='light' style={{ minWidth: 100 }} className={'border-0 rounded-pill py-1 ' + (toggle > 1 && 'bg-white shadow')}>{toggle2}</ToggleButton>
  </ToggleButtonGroup>
);


interface NotificationProps {
  date: string;
  link?: string;
  type: string; 
  project: string;
  canopyid?:number;
  isRead?: boolean; 
  projectid:string;
}

export const Notification: React.FC<NotificationProps> = ({
  date,
  type,
  project,
  canopyid,
  projectid,
  isRead = false,
}) => {
  // Function to determine the message based on the type
  const getMessage = (type: string,project:string) => {
    switch (type) {
      case 'Messaging':
        return `New message in ${project}`;
     case 'Project Scheduled':
      return `${project} is Scheduled for Testing`;
       case 'In Progress':
      return `${project} testing is in progress`;
       case 'Report Available':
      return `${project} Reports are available`;
      case 'Quote Available':
        return `Quote Available for ${project}`;
      default:
        return `No new notifications`; 
    }
  };
  const getIcon = (type: string) => {
    switch (type) {
      case 'Messaging':
        return <MessagingIcon width={25} />
        case 'Project Scheduled':
          return<TestsIcon width={25} />
           case 'In Progress':
          return <TestsIcon width={25} />
           case 'Report Available':
          return <TestsIcon width={25} />
      case 'Quote Available':
        return <TestsIcon width={25} />
      default:
        return `No Notification yet`; 
    }
  };
  const getLink = (type: string, projectid: string,canopyid:number) => {
    switch (type) {
      case 'Messaging':
        return `/messaging`
        case 'Project Scheduled':
          return `projects/${projectid}/${canopyid}`
           case 'In Progress':
          return `projects/${projectid}/${canopyid}`
           case 'Report Available':
          return `projects/${projectid}/${canopyid}`
      case 'Quote Available':
        return `projects/${projectid}/quote`
      default:
        return `#`; 
    }
  };

  return (
    <Link to={ getLink(type,projectid,canopyid)}>
      <Row className={`px-3 py-4 mt-0 border-top ${isRead ? '' : 'bg-secondary-subtle'}`}>
        <Col sm={2} className='m-0'>
          {getIcon(type)} 
        </Col>
        <Col sm={9} className='my-auto'>
          <div>{getMessage(type, project)}</div>
          <h6 className='m-0 mt-2 small text-gray'>{date}</h6>
        </Col>
        <Col sm={1} as='h2' className='m-0'>
          <BulletIcon className={isRead ? '' : 'text-secondary'} width={16} />
        </Col>
      </Row>
    </Link>
  );
};



interface ActivityProps {
  date: string;
  link?: string;
  text: string;
  className?: string;
}

export const Activity: React.FC<ActivityProps> = ({ date, text, link = null, className }) => (
  <Link to='#'>
    <Row className={'p-2 mt-0 ' + className}>
      <Col sm={12} className='my-auto small'>
        {text}
        <h6 className='m-0 small text-gray'>{date}</h6>
      </Col>
    </Row>
  </Link>
);



interface ChatProps {
  sent?: boolean;
  children: React.ReactNode;
}

export const Chat: React.FC<ChatProps> = ({ children, sent = false }) => (
  <div className={sent ? 'text-end' : ''}>
    <div className={'d-inline-block rounded-pill px-4 py-3 mb-3 shadow ' + (sent ? ' bg-secondary text-white' : ' bg-white')}>
      {children}
    </div>
  </div>
);



interface NavButtonProps {
  to: string;
  children: React.ReactNode;
}

export const NavButton: React.FC<NavButtonProps> = (props) => (
  <NavLink to={props.to} className={({ isActive }) => 'nav-link ' + (isActive && ' active')}>{props.children}</NavLink>
);



interface FadeAccordionProps {
  className?: string;
  alwaysOpen?: boolean;
  children: React.ReactNode;
  defaultActiveKey?: string;
}

export const FadeAccordion: React.FC<FadeAccordionProps> = (props) => {
  return (
    <FadeIn>
      <Accordion defaultActiveKey={props.defaultActiveKey} className={props.className} alwaysOpen={props.alwaysOpen}>
        {props.children}
      </Accordion>
    </FadeIn>
  );
};



interface FadeTableProps {
  className?: string;
  children: React.ReactNode;
  borderless?: boolean
  hover?: boolean
}

export const FadeTable: React.FC<FadeTableProps> = ({ className, children, borderless, hover = true }) => {
  return (
    <FadeIn>
      <Table hover={hover} responsive borderless={borderless} className={className + ' position-relative shadow mb-4 rounded-1 overflow-hidden bg-white'}>
        {children}
      </Table>
    </FadeIn>
  );
};



interface FadeModalProps {
  show: boolean;
  title?: string;
  onHide?: () => void;
  body?: React.ReactNode;
  size?: 'sm' | 'lg' | 'xl' |null;
  children?: React.ReactNode | null;
}

export const FadeModal: React.FC<FadeModalProps> = ({ size = null, body, title, show, onHide, children = null }) => {
  return (
    <>
      <Modal show={show} onHide={onHide} backdrop='static' size={size} keyboard={false} restoreFocus>
        {title ? <Modal.Header closeButton className='h1 m-0'>{title}</Modal.Header> : null}
        {body ? body : children}
      </Modal>
      {body && children}
    </>
  );
};



interface FadeAlertProps {
  show?: boolean;
  dismissFunction: () => void;
  children?: React.ReactNode | null;
}

export const FadeAlert: React.FC<FadeAlertProps> = ({ dismissFunction, children = null, show }) => (
  show && <Alert variant='danger' onClose={dismissFunction} dismissible>{children ?? 'There was an error loading the details.'}</Alert>
);
interface FadeAlertNoDataProps {
  show?: boolean;
  dismissFunction: () => void;
  children?: React.ReactNode | null;
}

export const FadeNoDataAlert: React.FC<FadeAlertNoDataProps> = ({ dismissFunction, children = null, show }) => (
  show && <Alert variant='danger' onClose={dismissFunction} dismissible>{children ?? 'There is no client data available.'}</Alert>
);



interface AnimatedProgressProps {
  width?: string;
  height?: string;
  variant?: string;
  className?: string;
  initialProgress: number;
}

export const AnimatedProgress: React.FC<AnimatedProgressProps> = ({
  width = '',
  height = '',
  className = '',
  initialProgress,
  variant = 'success',
}) => {
  const [progress, setProgress] = useState(0);
  const styles: React.CSSProperties = { transform: 'translateZ(0)', transition: 'width 1.2s ease-in-out' };
  useEffect(() => { const t = setTimeout(() => setProgress(initialProgress), 200); return () => clearTimeout(t) }, [initialProgress]);
  return <ProgressBar now={progress} variant={variant} className={className} style={{ ...styles, height, width }} />;
};



interface CountUpNumberProps {
  number: number;
  speed?: number;
}

export const CountUpNumber: React.FC<CountUpNumberProps> = ({ number, speed = 50 }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const int = setInterval(() => count < number && setCount(c => c + 1), speed);
    return () => clearInterval(int)
  }, [count, number, speed]);
  return <>{count}</>;
};



interface TableLinkProps {
  colSpan?: number;
  centered?: boolean;
  className?: string;
  to?: string | null;
  scrollTop?: boolean;
  children?: React.ReactNode;
  onClick?: () => void | null;
}

export const TableLink: React.FC<TableLinkProps> = ({
  scrollTop,
  colSpan = 0,
  children = null,
  to = null,
  centered = false,
  className = '',
  onClick = null
}) => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <tr className={className}>
      {colSpan > 0 && <td colSpan={colSpan} />}
      <td colSpan={100} className={centered ? 'text-center' : ''}>
        {to ?
          <Link to={to} onClick={scrollTop && scrollToTop} className='h5 stretched-link text-primary'>
            {!centered && <PlusIcon />}
            {children}
          </Link>
          :
          <Button variant='link' size='sm' className='h5 p-0 m-0 text-primary stretched-link' onClick={onClick}>
            {!centered && <PlusIcon />}
            {children}
          </Button>
        }
      </td>
    </tr>
  );
};


interface LinkButtonProps {
  variant?: string;
  className?: string;
  to?: string | null;
  children?: React.ReactNode;
  onClick?: () => void | null;
  disabled?:boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  onClick,
  className = '',
  children,
  variant = 'primary',
  disabled = false, // Default to false if not passed
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      return; // Stop further execution if disabled
    }
    onClick?.(); // Call onClick if provided
  };

  return to ? (
    <Link
      to={disabled ? '#' : to} // Set a dummy link if disabled
      className={`btn btn-${variant} ${className} ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {children}
    </Link>
  ) : (
    <Button
      variant={variant}
      onClick={handleClick}
      disabled={disabled}
      className={className}
    >
      {children}
    </Button>
  );
};




interface OverlayLinkProps {
  message?: string;
  disabled?: boolean;
  className?: string;
  to?: string | null;
  onClick?: () => void;
  placement?: Placement;
  body?: React.ReactNode | null;
  children?: React.ReactNode | null;
}

export const OverlayLink: React.FC<OverlayLinkProps> = ({ onClick, disabled, placement = 'right', children, to, className, body, message = 'Sorry. This project can no longer be edited or deleted.' }) => {
  const popover = (<Popover><Popover.Body>{message}</Popover.Body></Popover>);
  return (
    <>
      {!disabled ?
        (to ?
          <Link to={to} className={className}>{body}</Link>
          :
          <Link to={'#'} className={className} onClick={onClick}>{body}</Link>
        ) : (
          <OverlayTrigger placement={placement} overlay={popover} delay={{ show: 800, hide: 200 }}>
            <div className='position-relative'><Button disabled variant='link' className='p-0'>{body}</Button></div>
          </OverlayTrigger>
        )}
      {children}
    </>
  );
};


interface LinkBadgeProps {
  bg?: string;
  text?: Color;
  border?: string;
  bullet?: boolean;
  className?: string;
  to?: string | null;
  onClick?: () => void | null;
  children?: React.ReactNode | null;
}

export const LinkBadge: React.FC<LinkBadgeProps> = ({
  bullet,
  onClick,
  children,
  to = null,
  text = 'primary',
  className = 'ms-n2',
  bg = 'primary-subtle',
  border = 'primary-subtle',
}) => {
  return (to ?
    <Link to={to}>
      <Badge bg={bg} text={text} className={className + ' stretched-link border border-' + border}>
        {bullet && <BulletIcon />}
        {children}
      </Badge>
    </Link>
    :
    <Badge bg={bg} text={text} onClick={onClick} className={className + ' border border-' + border + (onClick ? ' pe-link' : '')}>
      {bullet && <BulletIcon />}
      {children}
    </Badge>
  );
};


interface FloatingFieldProps {
  type?: any;
  value?: any;
  label: string;
  main?: boolean;
  onChange?: any;
  onFocus?: any;
  feedback?: string;
  negative?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  defaultValue?: any;
  placeholder?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode | null;
}

export const FloatingField: React.FC<FloatingFieldProps> = ({
  style,
  label,
  value,
  negative,
  disabled,
  feedback = 'Please complete this required field',
  children,
  onChange,
  defaultValue,
  main = false,
  type = 'text',
  required = true,
  placeholder = '',
  className = 'mb-4'
}) => {
  return (
    <FloatingLabel className={className} label={label}>
      {!children &&
        <Form.Control
          type={type}
          value={value}
          style={style}
          autoFocus={main}
          spellCheck='false'
          required={required}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          as={children ? undefined : 'input'}
          autoComplete={main ? 'new-password' : 'off'}
          {...negative && {
            onBlur: (e) => { e.target.style.borderColor = ''; e.target.style.boxShadow = '' },
            onFocus: (e) => { e.target.style.borderColor = '#fa9c96'; e.target.style.boxShadow = '0 0 0 0.1rem #f99c96' }
          }}
        />
      }
      {children}
      <Form.Control.Feedback type='invalid'>{feedback}</Form.Control.Feedback>
    </FloatingLabel>
  );
};


