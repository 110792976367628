import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectById } from '../../Services/PtaasApiService';
import Phase from '../../Models/Phase';
import PhaseRow from './Rows/PhaseRow';
import Project from '../../Models/Project';



const PhaseList: React.FC = () => {
  const navigate = useNavigate();
  const { canopyid } = useParams();
  const { instance, accounts } = useMsal();
  const [phases, setPhases] = useState<Phase[]>(null);
  useEffect(() => {
    async function fetchProject(): Promise<void> {
      let returnedProject: Project;
      try { returnedProject = await getProjectById(parseInt(canopyid), instance, accounts[0]) }
      catch { }
      setPhases(returnedProject?.phases)
    }
    fetchProject();
  }, [navigate, canopyid, instance, accounts]);

  return (
    <div className='border my-4'>
      <Table className='m-0 table-info'>
        <tbody>
          {phases?.map(phase => <PhaseRow phase={phase} />)}
        </tbody>
      </Table>
    </div>
  );
};

export default PhaseList;