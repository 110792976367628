
import React from 'react';
import { NavDropdown, Image, Fade } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { logout } from '../../Services/AccountService';
import { useProfileContext } from './ProfileContext';
import { useTheme } from './ThemeContextType';
import { SignoutIcon } from './Icons';



const Menu: React.FC = () => {
  const { instance, accounts } = useMsal();
  const [open, setOpen] = React.useState(false);
  const { darkMode, toggleDarkMode } = useTheme(); // Use theme context

  const handleToggle = () => setOpen(!open);
  const { profilePicUrl } = useProfileContext();
  const profileImage = <Image src={profilePicUrl } roundedCircle fluid width={45} className='my-3' />;

  return (
    <NavDropdown show={open} title={profileImage} onClick={handleToggle} className='d-inline-block prefs'>
      <Fade in={open}>
        <div>
          <Image fluid width={100} roundedCircle src={profilePicUrl} className='d-block mt-4 mb-3 mx-auto' />
          <h2 className='text-center mb-1'>{accounts[0].name}</h2>
          <NavDropdown.Item as={Link} className='px-4 py-3 bold' to='/preferences/1'>Edit Profile</NavDropdown.Item>
          <div className="d-flex align-items-center">
          <span className="px-4 py-3 bold">Dark Mode</span>
            <label className="switch">
              <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} />
              <span className="slider"></span>
            </label>
          </div>
          <hr className='my-2' />
          <NavDropdown.Item className='px-4 py-3 mb-2 text-danger bold' onClick={() => logout(instance,accounts[0])}>
            <SignoutIcon /> Sign Out
          </NavDropdown.Item>
          
        </div>
      </Fade>
    </NavDropdown>
  );
};

export default Menu;