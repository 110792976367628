import { saveAs } from 'file-saver';
import { getPdfFromHtml } from '../Services/PtaasApiService';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import Phase from './Phase';
import Project from './Project';
import Ratings from '../Utilities/RatingProps';
import StrippedPhase from './StrippedPhase';
import StrippedFinding from './StrippedFinding';
import StrippedProject from './StrippedProject';



export const exportAsJson = (data: Project | Phase): void => {
	const strippedData = 'name' in data ? stripProject(data) : stripPhase(data);
	const filename = 'name' in data ? data.name + '.json' : data.title + '.json';
	var blob = new Blob([JSON.stringify(strippedData)], { type: 'text/json' });
	saveAs(blob, filename);
};


export const exportAsCsv = (data: Project | Phase): void => {
	const isProject = 'name' in data;
	const filename = `${isProject ? (data as Project).name : (data as Phase).title}.csv`;
	let headers = `${isProject ? 'Project,' : ''}Phase,Title,Rating,CVSS3,CVSS3 Vector,Category,Affected Components\n`;
	const body = isProject ? generateProjectCsv(data) : generatePhaseCsv(data);
	const csv = headers + body;
	let blob = new Blob([csv], { type: 'text/csv' });
	saveAs(blob, filename);
  };
  


export const exportAsPdf = async (data: Project | Phase, instance: IPublicClientApplication, account: AccountInfo): Promise<void> => {
	const isProject = 'name' in data;
	const phase = data as Phase;
	const project = data as Project;
	const fileData = isProject
		? await getPdfFromHtml(project.id, null, instance, account)
		: await getPdfFromHtml(phase.project, phase.id, instance, account);
	const url = URL.createObjectURL(fileData);
	const link = document.createElement('a');
	link.href = url;
	const filename = isProject ? `${project.name}.pdf` : `${phase.title}.pdf`;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
};


const stripProject = (project: Project): StrippedProject => {
	let strippedProject: StrippedProject = { name: project.name, status: project.status, phases: [] };
	project.phases.forEach((p) => {
		let phaseFindings: StrippedFinding[] = [];
		p.findings.forEach((f) => {
			phaseFindings.push({
				title: f.title,
				rating: Ratings[f.rating].name,
				summary: f.summary,
				cvss3_score: f.cvss3_score,
				cvss3_vector: f.cvss3_vector,
				category: f.attack_class__type,
				affectedComponents: f.affectedComponents,
				description: f.description,
				recommendation: f.recommendation,
				mitigations: f.mitigations,
				further_information: f.further_information,
			});
		});
		strippedProject.phases.push({
			title: p.title,
			findings: phaseFindings,
		});
	});
	return strippedProject;
};


const stripPhase = (phase: Phase): StrippedPhase => {
	let strippedPhase: StrippedPhase = { title: phase.title, findings: [] };
	phase.findings.forEach((f) => {
		strippedPhase.findings.push({
			title: f.title,
			rating: Ratings[f.rating].name,
			summary: f.summary,
			cvss3_score: f.cvss3_score,
			cvss3_vector: f.cvss3_vector,
			category: f.attack_class__type,
			affectedComponents: f.affectedComponents,
			description: f.description,
			recommendation: f.recommendation,
			mitigations: f.mitigations,
			further_information: f.further_information,
		});
	});
	return strippedPhase;
};


const generateProjectCsv = (project) => {
	let data = '';
	project.phases.forEach((p) => { data += generatePhaseCsv(p, project.name); });
	return data;
};


const generatePhaseCsv = (phase, projectName = null) => {
	let data = '';
	phase.findings.forEach((f) => {
		const cvss3 = f.cvss3_score ?? '';
		const vector = f.cvss3_vector ?? '';
		const category = f.attack_class__type ?? '';
		const affectedComponents =
			f.affectedComponents !== undefined
				? f.affectedComponents.join(', ')
				: '';
				data += `${projectName !== null ? `'${projectName}',` : ''}'${phase.title}','${f.title}','${Ratings[f.rating].name}','${cvss3}','${vector}','${category}','${affectedComponents}'\n`;
	});
	return data;
};