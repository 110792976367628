import React, { useState } from 'react';
import { Button, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FadeModal, NavButton } from './Transitions';
import { DashboardIcon, FaqsIcon, MessagingIcon, PlusIcon2 } from './Icons';
import Logo from './Logo';
import EditProject from '../Popups/EditProject';




interface SidebarProps {
	data_bs_theme: string;
}

const Sidebar: React.FC<SidebarProps> = ({ data_bs_theme }) => {
	const location = useLocation();
	const [show, setShow] = useState(false);
	const handleHide = () => setShow(false);
	const [complete, setComplete] = useState(false);
	const handleComplete = () => {setComplete(true);window.location.reload();};
	const handleShow = () => { setComplete(false); setShow(true) };
	const modals = {
		default: { text: 'New Project', modal: <EditProject action='create' close={handleHide} complete={handleComplete} /> },
		
	};
	const props = modals[location.pathname] || modals.default;

	return (
		<aside className='position-fixed top-0 start-0 shadow d-flex flex-column' data-bs-theme={data_bs_theme}>
			<Logo /> 
			<Nav className='flex-column'>
				<h6>Overview</h6>
				<NavButton to='/dashboard'><DashboardIcon />Dashboard</NavButton>
				<h6>Support</h6>
				<NavButton to='/faqs'><FaqsIcon />FAQs</NavButton>
				<NavButton to='/messaging'><MessagingIcon />Messaging</NavButton>
			</Nav>
			<FadeModal show={show} body={props.modal} title={complete ? '' : props.text} onHide={handleHide}>
				<Button className='w-100 mt-auto' onClick={handleShow}><PlusIcon2 />{props.text}</Button>
			</FadeModal>
		</aside>
	);
};

export default Sidebar;