import config from "../config.json";
import { AccountInfo, AuthenticationResult, IPublicClientApplication, SilentRequest, InteractionRequiredAuthError } from "@azure/msal-browser";

// export function logout(instance: IPublicClientApplication): void {
//     instance.logoutRedirect();
// }
export async function logout(instance: IPublicClientApplication, account: AccountInfo): Promise<void> {
    const request: SilentRequest = { ...config.loginRequest, account: account };
    let response: AuthenticationResult | null = null;

    try {
        // Acquire the token silently
        response = await instance.acquireTokenSilent(request);

        if (response) {
            // Access the ID token directly
            const idToken = response.idToken;

            if (!idToken) {
                console.error("ID Token is missing, unable to log out.");
                return;
            }

            // Perform logout with the idTokenHint and redirect URI
            await instance.logoutRedirect({
                idTokenHint: idToken,  // Pass the ID token directly
                postLogoutRedirectUri: config.msalConfig.auth.redirectUri || window.location.origin
            });
        }
    } catch (error) {
        console.error("Error during logout:", error);
    }
}

export async function getAccessToken(instance: IPublicClientApplication, account: AccountInfo): Promise<string> {
    const request: SilentRequest = { ...config.loginRequest, account: account };
    let response: AuthenticationResult | null = null;

    try {
        // Handle the redirect if there was one
        response = await instance.handleRedirectPromise();

        // If response is null, try to acquire the token silently
        if (!response) {
            response = await instance.acquireTokenSilent(request);
        }

        if (response) {
           
            return response.accessToken;
           
        }

        // If acquireTokenSilent failed, attempt redirect
        await instance.acquireTokenRedirect(request);

    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            // Redirect to login page if silent token acquisition fails
            await instance.acquireTokenRedirect(request);
        } else {
            console.error("Unexpected error during token acquisition:", error);
            throw error;
        }
    }

    // The function should not reach here; if it does, return null
    return null;
}
