import React, { useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';
import { FadeAccordion, LinkButton } from '../Global/Transitions';
import { EmptyCircleIcon, FilledCircleIcon } from '../Global/Icons';
import { UploadFiles, getPrereqQuestionByQuestionId, getQuestionsByQuestionId, getTestType, getprojectbyidforuser, putprojectdetails, uploadprereqinSharepoint } from '../../Services/PtaasApiService';
import Question from '../../Models/Question';
import TestType from '../../Models/TestType';
import SetupQuestionList from './SetupQuestionList';
import Tests from '../../Models/Tests';






interface SetupSectionProps {
  index: number;
  testType: string;
  group: 'prereq' | 'scoping';
  onInputChange: (updatedInputValue: any[], assignmentId: string) => void;
  OnfileChange: (files: FileEntry[]) => void;
  handleValidate: (isValid: boolean) => void;

}

interface SetupFormProps {
  testTypes: string[];
  group: 'prereq' | 'scoping';
  onStageChange: (newStage: number) => void;

}
interface FileEntry {
  id: string;
  file: File | null;
}


const SetupSection: React.FC<SetupSectionProps> = ({ index, group, testType, onInputChange, OnfileChange, handleValidate }) => {
  const { instance, accounts } = useMsal();
  const [testTypeName, setTestTypeName] = useState('');
  const [questions, setQuestions] = useState<Question[]>([]);
  useEffect(() => {
    async function fetchQuestions(): Promise<void> {
      let returnedPreReqs: Question[];
      let returnedScoping: Question[];
      let returnedTestType: TestType[];
      try {
        returnedTestType = await getTestType(instance, accounts[0]);
        const returnedType = returnedTestType.find(type => type.id === testType);
        setTestTypeName(returnedType.testTypeName);
        returnedScoping = await getQuestionsByQuestionId(instance, accounts[0], returnedType.questionID);

        returnedPreReqs = await getPrereqQuestionByQuestionId(instance, accounts[0], returnedType.questionID);
        setQuestions(group === 'prereq' ? returnedPreReqs : returnedScoping);
      }
      catch { }
    }
    fetchQuestions();
  }, [accounts, instance, group, testType]);

  return (
    <Accordion.Item eventKey={String(index)}>
      <Accordion.Button as='h3'>
        <EmptyCircleIcon />
        <FilledCircleIcon />
        {testTypeName}
      </Accordion.Button>
      <Accordion.Body>
        <SetupQuestionList questions={questions} group={group} onInputChange={onInputChange} OnfileChange={OnfileChange} handleValidate={handleValidate} />
      </Accordion.Body>
    </Accordion.Item>
  )
};


const SetupForm: React.FC<SetupFormProps> = ({ group, testTypes, onStageChange }) => {
  const { id } = useParams();
  const { instance, accounts } = useMsal();
  const [test, setTest] = useState<Tests>();

  const [isPreReqs, isScoping] = ['prereq', 'scoping'].map(g => group === g);
  const [inputData, setInputData] = useState<any[]>([]); // NEW: Accumulate all changes here
  const [files, setFiles] = useState<FileEntry[]>([]);
  const [isValid, setIsValid] = useState<boolean>(true);
  const fetchtest = useCallback(async (): Promise<void> => {
    let returnedtest: Tests;


    try {
      returnedtest = await getprojectbyidforuser(instance, accounts[0], id);
      setTest(returnedtest);

    }
    catch { }
  }, [instance, accounts, id]);
  useEffect(() => {
    fetchtest();
  }, [fetchtest]);


  const handlePrereqSubmit = async () => {
     handleSaveAllChanges();
    fetchtest();
  }
  const handleScopingSubmit = async () => {
     handleSaveAllChanges();
    onStageChange(2);
    fetchtest();
  }

  const handleFileChange = (updatedFiles: FileEntry[]) => {
    setFiles((prevFiles) => {
      // Create a shallow copy of the previous files to avoid direct state mutation
      const updatedFileList = [...prevFiles];

      // Iterate over the newly updated files (coming from child or elsewhere)
      updatedFiles.forEach((newFileEntry) => {
        const existingFileIndex = updatedFileList.findIndex(
          (entry) => entry.id === newFileEntry.id
        );

        if (existingFileIndex !== -1) {
          // If the file with the same ID already exists, update it
          updatedFileList[existingFileIndex] = newFileEntry;
        } else {
          // If the file doesn't exist, append the new one
          updatedFileList.push(newFileEntry);
        }
      });

      console.log("Updated files after change:", updatedFileList);

      // Return the updated list to setFiles (so state is updated properly)
      return updatedFileList;
    });
  };

  const handleInputChange = (updatedInputValue: any[], assignmentId: string) => {
    setInputData((prevData) => {
      const existingAssignmentIndex = prevData.findIndex((item) => item.assignmentId === assignmentId);

      if (existingAssignmentIndex > -1) {
        // Update the existing assignment
        const updatedData = [...prevData];
        updatedData[existingAssignmentIndex] = {
          assignmentId,
          [group]: updatedInputValue,
        };
        return updatedData;
      } else {
        // Add new assignment data
        return [...prevData, { assignmentId, [group]: updatedInputValue }];
      }
    });
  };


  // Save all changes in one go
  const handleSaveAllChanges = async () => {
    try {

      const updatedAssignments = test?.assignment.map((assignment) => {
        const changesForAssignment = inputData.find((data) => data.assignmentId === assignment.assignmentId);
        if (changesForAssignment) {
          return { ...assignment, [group]: changesForAssignment[group] };
        }
        return assignment;

      });

      if (group === 'scoping') {
        await uploadFiles(true)
        await uploaddatainSharepoint(true)
      } else {
        await uploadFiles(false);
        await uploaddatainSharepoint(false)
      }


      await putprojectdetails(
        instance,
        accounts[0],
        id,
        test?.status,
        test?.projectName,
        test?.projectOwner,
        test?.owneremail,
        test?.accepteddate,
        test?.availabledate,
        test?.comment,
        test?.testType,
        updatedAssignments
      );

      window.location.reload();
      
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const uploaddatainSharepoint = async (isScoping: boolean) => {
    try {
      await uploadprereqinSharepoint(instance, accounts[0], test?.id, isScoping);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  const uploadFiles = async (isScoping: boolean) => {
    try {
      for (const fileEntry of files) {
        await UploadFiles(instance, accounts[0], test?.ticketno, fileEntry.file, fileEntry.id, isScoping);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };


  const handleValidation = (validationStatus: boolean) => {
    setIsValid(validationStatus);
  };

  return (
    <>
      <FadeAccordion defaultActiveKey='0'>
        {testTypes.map((testType, index) => <SetupSection index={index} group={group} key={testType} testType={testType} onInputChange={handleInputChange} OnfileChange={handleFileChange} handleValidate={handleValidation} />)}
      </FadeAccordion>
      <Button disabled={isValid} onClick={handleSaveAllChanges} >
        Save All Changes
      </Button>
      {isPreReqs ? <LinkButton to={'/projects/' + id + '/'} disabled={isValid} onClick={() => handlePrereqSubmit()} className='btn'>Submit</LinkButton> : null}
      {isScoping ? <Button disabled={isValid} onClick={() => handleScopingSubmit()}>Submit</Button> : null}
    </>
  )
};

export default SetupForm;

