import React, { useState } from 'react';
import { DownIcon } from '../Global/Icons';
import { FadeTable, FadeModal, TableLink, ActiveToggle } from '../Global/Transitions';
import Tests from '../../Models/Tests';
import Project from '../../Models/Project';
import ProjectsRow from './Rows/ProjectsRow';
import EditProject from '../Popups/EditProject';




interface ProjectsListProps {
  tests: Tests[];
  projects?: Project[] ;
  OnProjectChange:()=>void;
 
}

const ProjectsList: React.FC<ProjectsListProps> = ({ tests,OnProjectChange }) => {
  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(1);
  const onChange = (value) => setToggle(value);
  const [complete, setComplete] = useState(false);
  const [sort] = useState({ key: '_ts', ascending: true });
  tests.sort((a, b) => (b[sort.key] || 0) - (a[sort.key] || 0));
  const handleHide = () => {setShow(false);OnProjectChange();};
  const handleComplete = () => {setComplete(true);OnProjectChange();};
  const handleShow = () => { setComplete(false);setShow(true) };
  const filtered = tests.filter(test => (toggle !== 1) === (test.status === 'Closed'));


  return (
    <FadeTable>
      <thead>
        <tr>
          <th colSpan={3}><h2>Projects</h2></th>
          <th  className='pt-1' colSpan={4}><ActiveToggle toggle={toggle} onChange={onChange} /></th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Project Owner</th>
          <th style={{ minWidth: 145 }}>Last Updated<DownIcon /></th>
          <th>Progress</th>
          <th colSpan={2} />
        </tr>
      </thead>
      <tbody>
      {filtered.length === 0 ? (<tr>
          <td colSpan={7} style={{ textAlign: 'center', padding: '20px' }}>
            No projects available.
          </td>
        </tr>
        ) : (
          filtered.map(test => <ProjectsRow test={test} key={test.id} />)
        )}<TableLink onClick={handleShow}>Create New Project</TableLink>
        <FadeModal show={show} onHide={handleHide}  title={complete ? '' : 'New Project'}>
          <EditProject action='create' close={handleHide} complete={handleComplete}  />
        </FadeModal>
      </tbody>
    </FadeTable>
  );
};

export default ProjectsList;