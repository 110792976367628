import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { LoaderIcon } from '../Components/Global/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { getPhaseById } from '../Services/PtaasApiService';
import { FadeAccordion, FadeAlert } from '../Components/Global/Transitions';
import Phase from '../Models/Phase';
import Finding from '../Models/Finding';
import ReportFinding from '../Components/Reports/ReportFinding';
import { Col, Row } from 'react-bootstrap';



const ReportFindingPage: React.FC = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(true);
  const { canopyid, phaseid, findid } = useParams();
  const [fetchError, setFetchError] = useState(false);
  const [finding, setFinding] = useState<Finding>(null);

  useEffect(() => {
    async function fetchPhase(): Promise<void> {
      setLoading(true);
      let returnedPhase: Phase;
      let returnedFinding: Finding;
      try {
        returnedPhase = await getPhaseById(parseInt(canopyid), parseInt(phaseid), instance, accounts[0]);
        returnedFinding = returnedPhase?.findings.find(f => f.id === parseInt(findid));
        setFinding(returnedFinding)
      } catch {
        setFetchError(true)
      } finally {
        setLoading(false);
      }
    }
    fetchPhase();
  }, [navigate, accounts, instance, phaseid, canopyid,findid]);

  return (loading ? <LoaderIcon /> : (
    <Row>
      <Col xl={12}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
        <FadeAccordion defaultActiveKey="1">
          <ReportFinding finding={finding} />
        </FadeAccordion>
      </Col>
    </Row>
  ));
};
export default ReportFindingPage;
