import React from 'react';
import '../../finding.css';
import { ShieldIcon } from '../Global/Icons';
import { FadeAccordion } from '../Global/Transitions';
import { Accordion, Col, Row } from 'react-bootstrap';
import Finding from '../../Models/Finding';
import RatingProps from '../../Utilities/RatingProps';



interface ReportFindingProps {
  finding: Finding;
}

const ReportFinding: React.FC<ReportFindingProps> = ({ finding }) => {
  const initialRating = finding?.rating || 0;
  const props = RatingProps[initialRating];

  return (
    <FadeAccordion defaultActiveKey='1'>
      <Accordion.Item eventKey='1'>
        <Accordion.Button as='div' className='accordion-static bg-white nogradient'>
          <Row className='w-100 text-dark'>
            <Col sm={6}><h3><ShieldIcon className={'me-2 ' + props.color} />{finding.title}</h3></Col>
            <Col sm={6}><h3 className={'me-n4 text-end ' + props.color}>{'#' + finding.id}</h3></Col>
          </Row>
        </Accordion.Button>
        <Accordion.Body>
          <Row>
            <Col md={6} as='p'>
              <strong>Category</strong>
              {finding.attack_class__type}
            </Col>
            <Col md={6} as='p'>
              <strong>Risk</strong>
              {props.name}
            </Col>
            <Col md={6} as='p'>
              <strong>CVSS3 Base Score</strong>
              {finding.cvss3_score + ' (' + finding.cvss3_vector + ')'}
            </Col>
            <Col md={6}>
              <strong>Summary</strong>
              <div dangerouslySetInnerHTML={{ __html: finding.summary }} />
            </Col>
            {finding.affectedComponents?.length > 0 &&
              <Col md={6} as='p'>
                <strong>Affected Components</strong>
                {finding.affectedComponents.map((affected, index) => <span key={index}>{affected}<br /></span>)}
              </Col>
            }
          </Row>
          <hr className='mb-4' />
          <strong>Description</strong>
          <div className='finding' dangerouslySetInnerHTML={{ __html: finding.description }} />
          <hr className='my-4' />
          <strong>Mitigations</strong>
          <div className='finding' dangerouslySetInnerHTML={{ __html: finding.mitigations }} />
          <strong>Further Information</strong>
          <div className='finding' dangerouslySetInnerHTML={{ __html: finding.further_information }} />
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ReportFinding;
