import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import {  useNavigate, useParams } from 'react-router-dom';
import { ChatIcon, LoaderIcon } from '../Components/Global/Icons';
import {  GetChatByTestId, getprojectbyidforuser, getProjectByTestId } from '../Services/PtaasApiService';
import { FadeAlert } from '../Components/Global/Transitions';
import Tests from '../Models/Tests';
import Assignment from '../Models/Assignment';
import ProjectPreReqs from '../Components/Overview/ProjectPreReqs';
import ProjectOverview from '../Components/Overview/ProjectOverview';
import ProjectTestTypes from '../Components/Overview/ProjectTestTypes';
import ProjectCommercials from '../Components/Overview/ProjectCommercials';
import Project from '../Models/Project';



const ProjectOverviewPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('');
  const { instance, accounts } = useMsal();
  const [test, setTest] = useState<Tests>();
  const [project, setProject] = useState('');
  const [loading, setLoading] = useState(true);
  const [testTypes, setTestTypes] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [projectdata,setProjectData]=useState<Project>();

const fetchProject= useCallback(async (): Promise<void> => {
    setLoading(true);
    let returnedTest: Tests;
    let returnedproject:Project;
    try {
      returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
      setTest(returnedTest);
      setStatus(returnedTest?.status);
      setTestTypes(returnedTest?.testType);
      setProject(returnedTest?.projectName);
      setAssignments(returnedTest?.assignment);
      returnedproject=await getProjectByTestId(instance,accounts[0],id);
      setProjectData(returnedproject);
    } catch {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  }, [accounts, instance,id]);
  useEffect(() => {
    fetchProject();
  }, [fetchProject]);
  const handleNavigate = async (id:string) => {
   
   const returnedid= await GetChatByTestId(instance, accounts[0],id);
    // at end navigate to 
    navigate(`/messaging?id=${returnedid}`);
  
  };

  return (loading ? <LoaderIcon /> : (
    <Row>
      <Col sm={8} as='h1'>{project}</Col>
      <Col sm={4} className='text-end'>
      <Button onClick={()=>handleNavigate(test.id)}><ChatIcon />Messaging</Button>
      </Col>
      <Col xl={8}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
        <ProjectOverview test={test} testTypes={testTypes} projectdata={projectdata} />
      </Col>
      <Col md={10} lg={8} xl={4}>
        {['Quote Requested', 'Quote Available'].includes(status) ? <ProjectCommercials status={status} /> : null}
        <ProjectPreReqs assignments={assignments} />
        <ProjectTestTypes test={test} testTypes={testTypes} isDisabled OnProjectTestTypeChange={fetchProject}/>
        {['Quote Requested', 'Quote Available'].includes(status) ? null : <ProjectCommercials status={status} />}
      </Col>
    </Row>
  ));
};

export default ProjectOverviewPage;


