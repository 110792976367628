import React from "react";
import config from "./config.json";
import { Outlet } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import Header from "./Components/Global/Header";
import Sidebar from "./Components/Global/Sidebar";
import { ThemeProvider, useTheme } from "./Components/Global/ThemeContextType";
import AppInitializer from "./Services/AppInitializer";

const PageLayout: React.FC = () => {
  // Ensure ThemeProvider wraps the component where useTheme is called
  return (
    <ThemeProvider> 
      <InnerPageLayout />
    </ThemeProvider>
  );
};

const InnerPageLayout: React.FC = () => {
  const { darkMode } = useTheme(); // Use useTheme inside the component

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={config.loginRequest}>
      <AppInitializer>
      <div data-bs-theme={darkMode ? "dark" : "Light"} className={`bg-basic theme-${darkMode ? "dark" : "Light"}`}>
        <Header data_bs_theme={darkMode ? "dark" : "Light"} />
        <Sidebar data_bs_theme={darkMode ? "dark" : "Light"} />
        <main className='bg-basic'>
          <Container fluid className='pb-6'>
            <Outlet />
          </Container>
        </main>
      </div>
      </AppInitializer>
    </MsalAuthenticationTemplate>
  );
};

export default PageLayout;