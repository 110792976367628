import React, { useCallback, useEffect, useState } from 'react';
import { Activity, FadeTable, LinkButton } from './Transitions';

import RecentActivitys from '../../Models/RecentActivity';
import { useMsal } from '@azure/msal-react';
import { GetRecentActivity, UpdateRecentActivity } from '../../Services/PtaasApiService';


const RecentActivity: React.FC = () => {
  const [activities, setActivities] = useState<RecentActivitys[]>([]);
  const { instance, accounts } = useMsal();
  
  const fetchRecentActivity = useCallback(async (): Promise<void> => {
    let returnedrecentActivity: RecentActivitys[];
    let filteractivity:RecentActivitys[];
    try {
      returnedrecentActivity = await GetRecentActivity(instance, accounts[0]);
      filteractivity=returnedrecentActivity.filter(r=>r.isRead===false);
      setActivities(filteractivity);
      
    } catch {
      
    } finally {
     
    }
  },[instance,accounts]);
  useEffect(() => {
    fetchRecentActivity();
  },[fetchRecentActivity]);
  function timeAgo(isoDate: string) {
    const date = new Date(isoDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
    if (diffInSeconds < 60) {
      return 'just now';
    } else if (diffInSeconds < 3600) { // less than 1 hour
      const mins = Math.floor(diffInSeconds / 60);
      return `${mins} minute${mins > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) { // less than 24 hours
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  }
  const  handleclearall = async () => {
    await UpdateRecentActivity(instance,accounts[0]);
    fetchRecentActivity();
};

  return (
    <FadeTable hover={false}>
      <thead>
        <tr>
          <th colSpan={100}>
            <h2>Recent Activity</h2>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
         
          <td colSpan={100} className='pb-0 pt-1'>
            {activities && activities.length > 0 ?( activities.map((activity, index) => (
              <Activity
                key={index}
                date={timeAgo(activity.updated)}
                text={activity.text}
                className={index < 1 ? '' : 'border-top'}
              />
            ))):( <div>No Recent Activity.</div>)}
          </td>
        </tr>
        <tr>
          <td colSpan={100}>
            <LinkButton variant='link' onClick={handleclearall} className='float-end'>Clear all</LinkButton>
          </td>
        </tr>
      </tbody>
    </FadeTable>
  );
};

export default RecentActivity;