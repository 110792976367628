import React from 'react';
import { FadeTable, LinkBadge, TableLink } from '../Global/Transitions';
import Phase from '../../Models/Phase';
import ReportFinding from './ReportFinding';
import ReportFindingList from './ReportFindingList';
import PhaseProps from '../../Utilities/PhaseProps';



interface ReportPhaseProps {
  phase: Phase;
  isSummary?: Boolean
}

const ReportPhase: React.FC<ReportPhaseProps> = ({ phase, isSummary }) => {
  const status = (phase.status && phase.status === "Completed") ? "Completed" : "In Progress";
  const props = PhaseProps[status] || PhaseProps.default;
  return (
    <div>
      <FadeTable className="nogradient" key={phase.id}>
        <thead>
          <tr>
            <th colSpan={5}>
              <h2 className={isSummary ? 'd-inline-block me-2' : ''}>{isSummary ? phase.title : 'Summary'}</h2>
              {isSummary ? <LinkBadge bg={props.bg} text={props.text}  border={props.border} className='mb-2'>{status}</LinkBadge> : null}
             
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>ID</th>
            <th>Finding</th>
            <th>Rating</th>
            <th colSpan={2}>Category</th>
          </tr>
        </thead>
        <tbody>
          {phase?.findings.map((finding) => <ReportFindingList key={finding.id} finding={finding} />)}
          {isSummary ? <TableLink to={phase.id + '/'} centered scrollTop className='text-center'>SEE FULL REPORT</TableLink> : null}
        </tbody>
      </FadeTable>
      {isSummary ? null : phase?.findings.map((finding) => <ReportFinding key={finding.id} finding={finding} />)}
    </div>
  );
};

export default ReportPhase;