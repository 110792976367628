import React, { useEffect, useRef, useState } from 'react';
import { SignoutIcon } from './Icons';
import { useMsal } from '@azure/msal-react';
import { logout } from '../../Services/AccountService';
import { useLocation, useParams } from 'react-router-dom'
import { FadeAccordion, FloatingField } from './Transitions';
import { Accordion, Col, Nav, Row, Tab, Form, Button, Image, Alert } from 'react-bootstrap';
import { UpdateProfilePicture ,DeleteProfilePicture} from '../../Services/PtaasApiService';
import { useProfileContext } from './ProfileContext';


interface PreferenceProps {
  profilePicture:string;
  onProfileChange:()=>void;
  user:User;
}

const Preferences: React.FC<PreferenceProps> = ({profilePicture: initialProfilePicture,onProfileChange,user }) => {
  const { tab } = useParams();
  const location = useLocation();
  const { instance ,accounts} = useMsal();
  const [activeKey, setActiveKey] = useState(1);
  const [currentProfilePicture, setCurrentProfilePicture] = useState(initialProfilePicture);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const { setProfilePicUrl } = useProfileContext();

 

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const activeTab = urlParams.get('tab');
    if (activeTab) {
      setActiveKey(parseInt(activeTab, 10));
    }
  }, [location.search]);
 
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files?.[0];
    if (files) {
      try {
        const response = await UpdateProfilePicture(instance, accounts[0], files);
        if (response.success) {  // Assuming the response includes a success flag and the new image URL
          setCurrentProfilePicture(response.newImageUrl);  // Update state with new image URL
          setUploadStatus('Profile picture updated successfully.');
          setProfilePicUrl(response.newImageUrl);
        } else {
          setUploadStatus(response.message || 'Failed to update profile picture.');
        }
      } catch (error) {
        setUploadStatus(error.message || 'Error updating profile picture.');
      }
    }
  };
  const handleDeleteProfilePicture = async () => {
   
      try {
        const response = await DeleteProfilePicture(instance,accounts[0]);
        if (response.success) {  // Assuming the response includes a success flag and the new image URL
            // Update state with new image URL
          setUploadStatus('Profile picture deleted successfully.');
          onProfileChange();
        } else {
          setUploadStatus('Failed to delete profile picture.');
        }
      } catch (error) {
        setUploadStatus('Error deleting profile picture.');
      }
    
  };
  return (
    <FadeAccordion defaultActiveKey='0'>
      <Accordion.Item eventKey='0' className='overflow-hidden position-relative'>
        <Accordion.Body className='gradient py-5'>
          <Row className='mb-5'>
            <Col lg={1}>
              <Image src={currentProfilePicture} className='mt-n1 me-2' roundedCircle fluid width={140} />
            </Col>
            <Col lg={10}>
              <h1 className='display-4 mb-0'>{accounts[0].name}<span className='text-gray'>/ Preferences</span></h1>
            </Col>
          </Row>
          <p hidden>{activeKey}</p>
          <Tab.Container defaultActiveKey={tab ? tab : '1'}>
            <Row className='m-0'>
              <Col sm={3} className='border-end p-0' style={{ minHeight: '50vh' }}>
                <Nav className='flex-column pe-4' variant='pills'>
                  <Nav.Link eventKey={1} className='bold'>Edit Profile</Nav.Link>
                 
                  <hr />
                  <Nav.Link eventKey={4} className='bold text-danger' onClick={() => logout(instance,accounts[0])}><SignoutIcon /> Sign Out</Nav.Link>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content className='px-4 pb-3 position-relative'>
                  <Tab.Pane eventKey={1}>
                    <Row style={{ width: 550 }}>
                      <Col lg={2}>
                        <Image src={currentProfilePicture} className='me-2' roundedCircle fluid width={80} />
                      </Col>
                      <Col lg={10} className='my-auto'>
                        <Button variant='metal'onClick={handleButtonClick}>New Picture</Button>
                        <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{display: 'none'}}/> 
                        <Button variant='gray' className='ms-3' onClick={handleDeleteProfilePicture}>Delete</Button>
                      </Col>
                    </Row>
                    {uploadStatus && <Alert variant={uploadStatus.startsWith('Failed') ? 'danger' : 'success'}>{uploadStatus}</Alert>}
                    <Form.Group className='mt-4'>
                      <FloatingField main label='Name' value={user.primaryContact.name} className='w-75 mb-4' />
                      <FloatingField disabled label='Email' value={accounts[0].idTokenClaims.emails} className='w-75 mb-4' />
                      {/* <Button>Save Profile</Button> */}
                    </Form.Group>
                  </Tab.Pane>
                  <Tab.Pane eventKey={2}>
                    <Form.Check type='switch' label='Dark Mode' className='bold' />
                  </Tab.Pane>
                 
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default Preferences;