import React, { useCallback, useEffect, useState } from 'react';
import { NotifsIcon } from './Icons';
import { Notification } from './Transitions';
import { Button, Col, NavDropdown, Row } from 'react-bootstrap';
import { GetNotification, getProjectByTestId, UpdateNotification } from '../../Services/PtaasApiService';
import { useMsal } from '@azure/msal-react';
import Notificationss from '../../Models/Notification';




const Notifications: React.FC = () => {
  const [notifs, setNotifs] = useState<Notificationss[]>([]);
  const { instance, accounts } = useMsal();
  const[read,setRead]=useState(0);
  const [projectIds, setProjectIds] = useState<{ [key: string]: number }>({});
  
  const FetchNotification = useCallback(async (): Promise<void> => {
  
     
    let returnedNotification: Notificationss[];
    let filternotification:Notificationss[];
    try {
      returnedNotification = await GetNotification(instance, accounts[0]);
      filternotification=returnedNotification.filter(noti=>noti.isRead===false);
      setRead(filternotification?.length);
      setNotifs(filternotification);
    } catch {
      
    } finally {
     
    }
  },[instance,accounts]);
  useEffect(() => {
    
    FetchNotification();
  }, [FetchNotification]);
  const getProjectIdFromTestId = useCallback(async (testId: string): Promise<number> => {
    try {
      // Replace this with the actual API call
      const response = await getProjectByTestId(instance,accounts[0],testId);
      return response.id;
    } catch (error) {
      console.error(error);
      return 0; // Return a default value if an error occurs
    }
  }, [instance,accounts]);
  useEffect(() => {
    const fetchProjectIds = async () => {
      const ids: { [key: string]: number } = {};
      for (const notif of notifs) {
        ids[notif.projectId] = await getProjectIdFromTestId(notif.projectId);
      }
      setProjectIds(ids);
    };
    fetchProjectIds();
  }, [notifs,getProjectIdFromTestId]);

  function timeAgo(isoDate: string) {
    const date = new Date(isoDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
    if (diffInSeconds < 60) {
      return 'just now';
    } else if (diffInSeconds < 3600) { // less than 1 hour
      const mins = Math.floor(diffInSeconds / 60);
      return `${mins} minute${mins > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) { // less than 24 hours
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  }
  const handleReadstatus = async () => {
         
          await UpdateNotification(instance, accounts[0]);
        FetchNotification();
    
  };
 
  
 
  return (
    <NavDropdown title={<NotifsIcon unread={read}  />} className='d-inline-block me-2 notifs'>
      <div className='overflow-hidden position-relative w-100'>
        <Row className='px-3 py-2 mb-1 overflow-hidden'>
          <Col sm={6} as='h2' className='m-0'>Notifications</Col>
          <Col sm={6} className='my-auto text-end'>
            <Button variant='link'onClick={handleReadstatus} className='p-0 text-gray'>Mark all as read</Button>
          </Col>
        </Row >
        
        {notifs.map((notif, index) => (
          <Notification
            key={index}
            date={timeAgo(notif.createdAt)}
            type={notif.type}
            project={notif.project}
            canopyid={projectIds[notif.projectId] || 0}
            projectid={notif.projectId}
            isRead={notif.isRead || false}
          />
          
        )
        
        )}
        
      </div>
    </NavDropdown>
  );
};

export default Notifications;