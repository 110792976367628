import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Preferences from "../Components/Global/Preferences";
import { useMsal } from "@azure/msal-react";
import { GetProfilePicture, GetUserPreferenceDetails } from "../Services/PtaasApiService";
import { LoaderIcon } from "../Components/Global/Icons";




const PreferencesPage: React.FC = () => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState('');
  const [user,setUser]=useState<User>();
  const fetchProfilePic = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {

      const imageData = await GetProfilePicture(instance, accounts[0]);
      const imageUrl = URL.createObjectURL(imageData); // Create a URL for the Blob
      setProfilePicture(imageUrl);
      let userdetails:User;
      userdetails=await GetUserPreferenceDetails(instance,accounts[0]);
      setUser(userdetails);

    } catch {

    } finally {
      setLoading(false);
    }
  }, [accounts, instance]);
  useEffect(() => {
    fetchProfilePic();
  }, [fetchProfilePic]);
  return (loading ? <LoaderIcon /> : (
    <Row>
      <Col sm={12}>
        <Preferences profilePicture={profilePicture} onProfileChange={fetchProfilePic} user= {user}/>
      </Col>
    </Row>
  ));
};

export default PreferencesPage;