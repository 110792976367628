import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { ChatIcon, LoaderIcon } from '../Components/Global/Icons';
import {  useParams } from 'react-router-dom';
import { FadeAlert, LinkButton } from '../Components/Global/Transitions';
import {  GetChatByTestId, GetUserPreferenceDetails, getprojectbyidforuser, getquotebytestid } from '../Services/PtaasApiService';
import Tests from '../Models/Tests';
import lineItem from '../Models/LineItems';
import ProjectQuote from '../Components/Overview/ProjectQuote';
import ProjectPreReqs from '../Components/Overview/ProjectPreReqs';
import ProjectTestTypes from '../Components/Overview/ProjectTestTypes';
import Quote from '../Models/Quote';



const ProjectQuotePage: React.FC = () => {
  const { id } = useParams();
  const { instance, accounts } = useMsal();
  const [test, setTest] = useState<Tests>();
  const [project, setProject] = useState('');
  const [loading, setLoading] = useState(true);
  const [testTypes, setTestTypes] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  const [lineItems, setLineItems] = useState<lineItem[]>([]);
  const [quote,setQuote]=useState<Quote>();
  const[userinfo,setUserInfo] =useState<User>();
  const [chatid,setChatid]=useState('');
  const fetchQuote= useCallback(async (): Promise<void> => {
    setLoading(true);
    let returnedTest: Tests;
    let returnedquotes:Quote;
    try {
      returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
      setTest(returnedTest);
      setTestTypes(returnedTest?.testType);
      setProject(returnedTest?.projectName);
      const returnedid= await GetChatByTestId(instance, accounts[0],returnedTest?.id);
      setChatid(returnedid);
      returnedquotes=await getquotebytestid(instance,accounts[0],id);
      setQuote(returnedquotes);
      setLineItems(returnedquotes?.lineItem);
      const returnedUserinfo=await GetUserPreferenceDetails(instance,accounts[0]);
      setUserInfo(returnedUserinfo);
      
     
    } catch {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  }, [accounts, instance,id]);
  useEffect(() => {
    
    fetchQuote();
  }, [fetchQuote]);

  return (loading ? <LoaderIcon /> : (
    <Row>
      <Col sm={8} as='h1'>{project}</Col>
      <Col sm={4} className='text-end'>
        <LinkButton to={`/messaging?id=${chatid}`}><ChatIcon />Messaging</LinkButton>
      </Col>
      <Col xl={8}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
        <ProjectQuote lineItems={lineItems} test={test} testTypes={testTypes} Quote={quote} userinfo={userinfo} />
      </Col>
      <Col md={10} lg={8} xl={4}>
         <ProjectPreReqs assignments={test.assignment} /> 
        <ProjectTestTypes test={test} testTypes={testTypes} isDisabled OnProjectTestTypeChange={fetchQuote}/>
      </Col>
    </Row>
  ));
};

export default ProjectQuotePage;