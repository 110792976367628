import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import config from "./config.json";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { ProfileProvider } from "./Components/Global/ProfileContext";



const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(config.msalConfig);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MsalProvider instance={msalInstance}>
				<ProfileProvider>
					<App />
				</ProfileProvider>
			</MsalProvider>
		</BrowserRouter>
	</React.StrictMode>
);

