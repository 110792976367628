const TestProps = {
  'Created': { nextStatus: 'Quote Requested', progress: '11', text: 'secondary', bg: 'secondary-subtle', border: 'secondary-subtle', quoteText: '' },
  'Quote Requested': { nextStatus: 'Quote Available', progress: '21', text: 'warning-emphasis', bg: 'warning-subtle', border: 'warning-subtle', quoteText: 'Our team will share a quote with you shortly (typically 1-2 business days).' },
  'Quote Available': { 
    nextStatus: ['Quote Accepted/Quote Rejected'], 
    progress: '55', 
    text: 'danger', 
    bg: 'danger-subtle', 
    border: 'danger-subtle', 
    quoteText: 'A new quote for this project is now available for review.' 
  },
  'Quote Accepted': { nextStatus: 'Project Scheduled', progress: '34', text: 'primary', bg: 'primary-subtle', border: 'primary-subtle', quoteText: 'Quote was approved.' },
  'Quote Rejected': { nextStatus: 'Closed', progress: '0', text: 'danger', bg: 'danger-subtle', border: 'danger-subtle', quoteText: 'The quote has been rejected. Please contact us for further assistance.' },
  'Project Scheduled': { nextStatus: 'In Progress', progress: '59', text: 'success-emphasis', bg: 'success-subtle', border: 'success-subtle', quoteText: 'Quote was approved.' },
  'In Progress': { nextStatus: 'Report Available', progress: '59', text: 'success-emphasis', bg: 'success-subtle', border: 'success-subtle', quoteText: 'Quote was approved.' },
  'Report Available': { nextStatus: 'Closed', progress: '34', text: 'primary', bg: 'primary-subtle', border: 'primary-subtle', quoteText: 'Quote was approved.' },
  'Closed': { nextStatus: null, progress: '100', text: 'dark', bg: 'light', border: 'gray-subtle', quoteText: 'The process is closed.' },
};

export default TestProps;
