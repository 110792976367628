import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExpandIcon, ShieldIcon } from '../Global/Icons';
import Finding from '../../Models/Finding';
import RatingProps from '../../Utilities/RatingProps';



interface ReportFindingListProps {
  finding: Finding;
}

const ReportFindingList: React.FC<ReportFindingListProps> = ({ finding }) => {
  const { id, canopyid } = useParams();
  const { name, color } = RatingProps[finding?.rating || 0];
  const link = '/projects/' + id + '/' + canopyid + '/reports/' + finding.phase + '/finding/' + finding.id;

  return (
    <tr key={finding.id}>
      <td width={90}>{finding.id}</td>
      <td>{finding.title}</td>
      <td width={165}><ShieldIcon className={'me-2 ' + color} />{name + ' (' + finding.cvss3_score + ')'}</td>
      <td width={172}>{finding.attack_class__type ? finding.attack_class__type : 'Unassigned'}</td>
      <td width={60}><Link to={link} ><ExpandIcon/></Link></td>
    </tr>
  );
};

export default ReportFindingList;
